<div class="header bg-primary">
  <div fxLayout="row wrap" fxLayoutAlign="space-between center">
    <h2 class="fw-500 secondary-font">Invoice Report</h2>
  </div>
</div>

<div mat-dialog-content>
  <div id="pdfTable">
    <mat-card class="p1" style="padding:3px !important;">
      <div fxFlex="100" fxLayoutAlign="center"  class="p-0 primary-color" style="background:white;margin:0px">
        <h1>{{data.vend.vendor.vendorName}}</h1>
      </div>
    </mat-card>
    <mat-card class="p1"  style="padding:3px !important">
      
      <div class="table-wrapper">
      <div fxFlex="100" class="p-0" style="background:white">
        <div class="cr">
          <p class="mt-0 mb-1">
            <span style="width:130px;padding-left: 26px;
 display: inline-block">Order No </span
            ><span>: #{{data.res.orderNo}} </span>
          </p>
          <p class="mt-0 mb-1">
            <span style="width:130px;padding-left: 26px;
 display: inline-block">Customer</span
            ><span>: {{data.res.customerName}}</span>
          </p>
          
          <p class="mt-0 mb-1">
            <span style="width:130px;padding-left: 26px;
 display: inline-block">Date</span
            ><span>: {{data.res.orderDate | date:'dd-MM-yyyy HH:mm'}} </span>
          </p>
          <p class="mt-0 mb-1">
            <span style="width:130px;padding-left: 26px;
 display: inline-block">Delivery Type</span
            ><span>: {{data.res.deliveryType}} </span>
          </p>
          
          <p class="mt-0 mb-1" *ngIf="data.det.orderAddress">
            <span style="width:130px;padding-left: 26px;
 display: inline-block">Address</span
            ><span>: {{data.det.orderAddress[0].streetName}},{{data.det.orderAddress[0].buildingName}},{{data.det.orderAddress[0].geoLocation}}</span>
          </p>
          <p class="mt-0 mb-1" *ngIf="data.det.orderAddress">
            <span style="width:130px;padding-left: 26px;
 display: inline-block">Contact no</span
            ><span>: {{data.det.orderAddress[0].contactNo}} </span>
          </p>
          <p class="mt-0 mb-1">
            <span style="width:130px;padding-left: 26px;
 display: inline-block">Payment Type</span
            ><span>: {{data.res.paymentType}} </span>
          </p>
        </div>
      </div>
      </div>
    </mat-card>
  
    <mat-card class="p-1">
      <div class="table-wrapper">
        <div class="mat-table theme-table review-order-table-invoice">
          <div class="mat-header-row" style="min-height: 48px;min-width: 350px;max-width: 350px;">
              <!-- <div class="mat-header-cell image">Image</div> -->
              <div class="mat-header-cell">Items</div>
              <div class="mat-header-cell price">Unit Price</div>      
              <div class="mat-header-cell quantity">Qty</div>
              <div class="mat-header-cell total">Total</div> 
              <!-- <div class="mat-header-cell total" *ngIf="order.activeStatus!='Delivered' && order.activeStatus!='Cancel' && order.activeStatus!='Delivery Process' 
              && order.activeStatus!='Out for delivery' &&  order.activeStatus!='Ready to Pickup' "></div>  -->
          </div> 
          <div *ngFor="let item of data.det.orderItem; let i=index;" class="mat-row item-row" style="min-height: 48px;min-width: 350px;max-width: 350px;">
              <!-- <div class="mat-cell image"><img [src]="item.image.small" alt="item"></div> -->
              <div class="mat-cell"><a >{{item.menuName}}<span  *ngIf="item.subMenuName">-{{item.subMenuName}}</span></a></div>
              <div class="mat-cell price">  
                  <span *ngIf="!item.discount" > 
                     {{item.rate | number : '2.2'}}  
                  </span> 
                  <!-- <span *ngIf="item.discount" class="primary-color py-3">  
                      {{ (item.price - (item.price * (item.discount / 100))) | currency : 'USD' : 'symbol' : '1.2-2'}}
                  </span>   -->
              </div>      
              <!-- <div class="mat-cell quantity">{{item.cartCount}}</div> -->
              <div class="mat-cell quantity" ><span>{{item.qty}}</span></div>
              <div class="mat-cell">
                  <span > {{item.rate | number : '2.2'}}</span>
                  <!-- <span *ngIf="!item.discount" class="primary-color py-3"> 
                      {{item.price*item.cartCount | currency : 'USD' : 'symbol' : '1.2-2'}}  
                  </span> 
                  <span *ngIf="item.discount" class="primary-color py-3">   
                      {{ (item.price - (item.price * (item.discount / 100)))*item.cartCount | currency : 'USD' : 'symbol' : '1.2-2'}}
                  </span>   -->
              </div> 
              <!-- <div class="mat-cell text-right" 
              *ngIf="order.activeStatus!='Delivered' && order.activeStatus!='Cancel' && order.activeStatus!='Delivery Process' 
              && order.activeStatus!='Out for delivery' &&  order.activeStatus!='Ready to Pickup' " class="mat-cell total">
              <button  mat-icon-button color="warn" (click)="remove(order,OrderDetails,i)"  matTooltip="cancel Item">
                  <mat-icon>cancel</mat-icon>
              </button> 
              </div> -->
          </div> 
          <div class="mat-row">
              <div class="mat-cell"></div>
              <div class="mat-cell price"></div>
              <div class="mat-cell quantity"></div>
              
              <div class="mat-cell ">
              <div fxLayout="column" fxLayoutAlign="center"  style="width: 170px;">
                  <span>SubTotal:</span>
                  <!-- <span>Delivery Fee:</span> -->
                  <span *ngIf="data.det" >VAT {{data.det.trnsOrder.tax}}%:</span>
                  <span>Total:</span>
              </div>        
              </div>
              <div class="mat-cell total">
                  <div fxLayout="column" fxLayoutAlign="center " style="width: 100px;">
                      <span *ngIf="data.det.trnsOrder">AED {{ data.det.trnsOrder.grossValue | number : '2.2'}}</span>
                      <!-- <span>{{ OrderDetails.trnsOrder.deliverycharges | currency : 'AED' : 'symbol' : '1.2-2'}}</span> -->
                      <span *ngIf="data.det.trnsOrder">AED {{data.det.trnsOrder.taxAmount | number : '2.2'}}</span>
                      <!-- <span>{{ OrderDetails.trnsOrder.deliverycharges | currency : 'AED' : 'symbol' : '1.2-2'}}</span> -->
                      <span class="primary-color" *ngIf="data.det.trnsOrder">AED {{data.det.trnsOrder.netAmount | number : '2.2'}}</span>
                  </div>
              </div> 
            
              
            </div>
        </div>
      </div>
  
    </mat-card>
  </div>
  
</div>
<div mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100 px-1">
    <button mat-raised-button color="warn" type="button" mat-dialog-close>
      Close
    </button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled] = "status === true"
      (click)="requestUsb()"
    >
      Connect Bluetooth
    </button>
    
    <button
      mat-raised-button
      color="primary"
      type="submit"
      (click)="print()"
    >
      Print
    </button>
  </div>
</div>
