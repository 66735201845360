<div class="section default mobile-app mt-0 pb-0">
    <div class="theme-container">  
        <div fxLayout="row wrap">
            <div fxFlex="100" fxFlex.gt-xs="35" class="px-3">
                <img src="assets/images/others/app-mob.png" alt="mobile-app" class="mw-100 d-block m-auto">
            </div>
            <div fxFlex="100" fxFlex.gt-xs="65" class="px-3">
                <h1 class="section-title text-left">The Best Food Delivery App</h1>
                <p>Online food delivery apps are certainly one of the vital apps that any food lover will have on their smartphones. And if you are a foodie then you definitely don’t just like food but you “Love” food. You literally wait for your next meal. Well, you are not the only one. There are millions of foodies who just incorporate food into their lifestyle.</p>
                <p>Nowadays, there are tons of useful mobile apps that are literally dedicated to food. Right from piling up recipes, watching someone cook to ordering food online, there are online food delivery apps for every foodie.</p>
                <p>Download our online food delivery apps by clicking below buttons:</p>
                <div fxLayout="row wrap" fxLayoutAlign="start center" class="pt-3">
                    <img src="assets/images/others/app-download-btn1.png" alt="btn1" class="mw-100 d-block m-1">
                    <img src="assets/images/others/app-download-btn2.png" alt="btn2" class="mw-100 d-block m-1">
                </div> 
            </div> 
        </div>  
    </div>
</div>