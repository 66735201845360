<div class="header bg-primary">
    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <h2 class="fw-500 secondary-font">Order #{{order.orderNo}}</h2>
        <app-dialog-header-controls [dialogRef]="dialogRef"></app-dialog-header-controls> 
    </div>   
</div> 
<mat-dialog-content>  
    <div class="pt-3"> 
        <div fxLayout="row wrap" class="flex-wrapper"> 
        
        <ng-container *ngIf="OrderDetails.orderAddress && OrderDetails.orderAddress.length>0 && order.deliveryType==='Home Delivery' || order.deliveryType==='Self-Pick Up'">
            <div fxFlex="100" class="px-3 mb-2">
                <h2 class="secondary-font">Delivery Address</h2>
            </div>
            <div fxFlex="100" class="px-3 mb-2">
                <div class="divider"></div>
            </div>  
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Name:</p>
                <p>{{order.customerName}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Phone:</p>
                <p *ngIf="OrderDetails.orderAddress">{{OrderDetails.orderAddress[0].contactNo}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Door No:</p>
                <p *ngIf="OrderDetails.orderAddress">{{OrderDetails.orderAddress[0].poBoxNo}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Street Name:</p>
                <p *ngIf="OrderDetails.orderAddress">{{OrderDetails.orderAddress[0].streetName}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Location:</p>
                <p *ngIf="OrderDetails.orderAddress">{{OrderDetails.orderAddress[0].geoLocation}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Country:</p>
                <p *ngIf="OrderDetails.orderAddress">{{OrderDetails.orderAddress[0].country}}</p>
            </div>
        </ng-container>

        <ng-container *ngIf="order.deliveryType=='Curb Side Pickup'">
            <div fxFlex="100" class="px-3 mb-2">
                <h2 class="secondary-font">Order Details</h2>
            </div>
            <div fxFlex="100" class="px-3 mb-2">
                <div class="divider"></div>
            </div>  
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Name:</p>
                <p *ngIf="order">{{order.customerName}}</p>
            </div>
            <!-- <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Driver Name:</p>
                <p >{{order.driverName}}</p>
            </div> -->
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Vehicle No:</p>
                <p>{{order.carNumber}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Car Brand:</p>
                <p>{{order.carMake}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Car Model:</p>
                <p>{{order.carModel}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Car Color:</p>
                <p>{{order.carColor}}</p>
            </div>
            <!-- <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Driver Contact No:</p>
                <p>{{order.driverPhone}}</p>
            </div> -->
            

        </ng-container>

        <ng-container *ngIf="role === 'Callcenter'">
            <div fxFlex="100" class="px-3 mb-2">
                <h2 class="secondary-font">Vendor Address</h2>
            </div>
            <div fxFlex="100" class="px-3 mb-2">
                <div class="divider"></div>
            </div>  
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Restuarant Name:</p>
                <p>{{VendorDetails.vendorName}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Contact Number:</p>
                <p *ngIf="OrderDetails.orderAddress">{{VendorDetails.contactNo}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Contact Number 1:</p>
                <p *ngIf="OrderDetails.orderAddress">{{VendorDetails.contactNo1}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Contact Person Name:</p>
                <p *ngIf="OrderDetails.orderAddress">{{VendorDetails.contactPersonName}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Address:</p>
                <p *ngIf="OrderDetails.orderAddress">{{VendorAddress.streetName}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">City:</p>
                <p *ngIf="OrderDetails.orderAddress">{{VendorAddress.location}}</p>
            </div>
        </ng-container>
       

            <!-- <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Email:</p>
                <p>{{deliveryAddress.email}}</p>
            </div> -->
            <!-- <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Country:</p>
                <p>{{deliveryAddress.country}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">City:</p>
                <p>{{deliveryAddress.city}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Place:</p>
                <p>{{deliveryAddress.place}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Zip/PostalCode:</p>
                <p>{{deliveryAddress.postalCode}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Address:</p>
                <p>{{deliveryAddress.address}}</p>
            </div>  -->

            <div fxFlex="100" class="px-3 mb-2 mt-3">
                <h2 class="secondary-font">Order Process</h2>
            </div>
            <div fxFlex="100" class="px-3 mb-2">
                <div class="divider"></div>
            </div> 

            <div fxLayout="column" *ngIf="order" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Order Status: 
                    <span *ngIf="order.activeStatus!='cancel'" class="primary-color mx-1"><strong> {{order.activeStatus}}</strong></span>
                    <span *ngIf="order.activeStatus=='cancel'" class="danger-color mx-1"><strong> {{order.activeStatus}}</strong></span>
                 </p>
            </div>
            
            <div fxLayout="column" *ngIf="order &&  order.deliveryType==='Curb Side Pickup' && order.activeStatus=='Ready to Pickup' " fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <!-- <p *ngIf="order.activeStatus=='Ready to Pickup' && order.deliveryType==='Curb Side Pickup' " class="text-muted mb-1">
                    Click to action:</p> -->
                <button mat-raised-button color="primary" *ngIf="order.activeStatus=='Ready to Pickup' && order.deliveryType==='Curb Side Pickup'" 
                 (click)="onStatusSelectionChange(OrderDetails,order,'Delivered')"> Click to Completed Order</button>
            </div>
            
            <div fxLayout="column" *ngIf="order && !Delivery_details && order.activeStatus=='Awaiting Acceptance'" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <mat-form-field  appearance="fill"  *ngIf="order.activeStatus=='Awaiting Acceptance'">
                    <mat-select (selectionChange)="onSelectionChange($event, order)" placeholder="Select Delivery Boy">
                        <mat-option value="0" > Select Delivery Boy </mat-option>
                        <mat-option *ngFor="let list of Userlist" [value]="list.id">
                            {{list.userName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
           
            <div fxLayout="column" *ngIf="order && !Delivery_details" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <!-- <p *ngIf="order.activeStatus!='Delivery Process' && order.activeStatus!='Delivered' 
                && order.activeStatus!='Cancel'" class="text-muted mb-1">Click to action:</p> -->

                
          

               
                    <p *ngIf="order.activeStatus=='Delivery Process'" class="text-muted mb-1">Select Delivery Boy:</p>
                
                
                    <!-- <button mat-raised-button color="primary" *ngIf="order.activeStatus=='Awaiting Acceptance'"  (click)="onStatusSelectionChange(OrderDetails,order,'Your order is Being Prepared')">Click to Accept Order</button> -->
                    <button mat-raised-button color="primary" *ngIf="order.activeStatus=='Awaiting Acceptance' && order.deliveryType==='Home Delivery'"  (click)="onStatusSelectionChange(OrderDetails,order,'Delivery Process')">Click to Delivered Order</button>
                    <button mat-raised-button color="primary" *ngIf="order.activeStatus=='Awaiting Acceptance' && order.deliveryType==='Self-Pick Up'"  (click)="onStatusSelectionChange(OrderDetails,order,'Out for delivery')"> Ready to delivery</button>
                    <button mat-raised-button color="primary" *ngIf="order.activeStatus=='Out for delivery' && order.deliveryType==='Self-Pick Up'"  (click)="onStatusSelectionChange(OrderDetails,order,'Delivered')"> Click to Completed Order</button>

                    <button mat-raised-button color="primary" *ngIf="order.activeStatus=='Awaiting Acceptance' && order.deliveryType==='Curb Side Pickup'"  (click)="onStatusSelectionChange(OrderDetails,order,'Ready to Pickup')"> Ready to Pickup</button>
                    <button mat-raised-button color="primary" *ngIf="order.activeStatus=='Ready to Pickup' && order.deliveryType==='Curb Side Pickup'"  (click)="onStatusSelectionChange(OrderDetails,order,'Delivered')"> Click to Completed Order</button>
               
                
                
                
                    
                 
            </div>
            <div fxLayout="column" *ngIf="order" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <!-- <p *ngIf="order.activeStatus!='Delivery Process' && order.activeStatus!='Delivered' &&  order.activeStatus!='Cancel'" class="text-muted mb-1">Click to action:</p> -->
                <!-- <button mat-raised-button color="primary" 
                *ngIf="order.activeStatus!='Ready to Pickup' && order.activeStatus !='Delivered' && order.activeStatus!='Delivery Process' 
                && order.activeStatus!='Out for delivery' && order.activeStatus!='Cancel' "  
                    (click)="onStatusSelectionChange(OrderDetails,order,'Cancel')"> Click to Cancel Order</button> -->
            </div>

            <div fxFlex="100" class="px-3 mb-1">
                <h2 class="secondary-font">Meal Plan Type</h2>
            </div>
            <div fxFlex="100" class="px-3 mb-2">
                <div class="divider"></div>
            </div> 
            <div fxLayout="column" *ngIf="OrderDetails.orderAddress" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">
                     <strong>{{OrderDetails.trnsOrder.mealPlanType }}</strong>
                 </p>
            </div>

            

            <div *ngIf="Delivery_details" fxFlex="100" class="px-3 mb-2 mt-1">
                <h2 class="secondary-font">Driver Details</h2>
            </div>
            <div *ngIf="Delivery_details" fxFlex="100" class="px-3">
                <div class="divider"></div>
            </div> 

            <div fxLayout="column" *ngIf="Delivery_details" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                
                <p *ngIf="Delivery_details" class="text-muted mb-1">Delivery Name:</p>
                <span *ngIf="Delivery_details" class="mx-1">
                    <span *ngIf="order.deliveryType!='Curb Side Pickup'">{{ Select_Userlist[0].userName }}</span>
                    <span *ngIf="order.deliveryType==='Curb Side Pickup'">{{ OrderDetails.orderDelivery.driverName }}</span>
                     
                    </span>
            </div>
            <div fxLayout="column" *ngIf="Delivery_details" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p *ngIf="Delivery_details" class="text-muted mb-1">Delivery ContactNo:</p>
                <span *ngIf="Delivery_details" class="mx-1"> 
                    <span *ngIf="order.deliveryType!='Curb Side Pickup'">{{ Select_Userlist[0].mobileNo }}</span>
                    <span *ngIf="order.deliveryType==='Curb Side Pickup'">{{ OrderDetails.orderDelivery.driverPhoneNo }}</span>
                </span>
            </div>
            <div fxFlex="100" class="px-3"></div>
            


            <div fxFlex="100" class="px-2 mb-2 mt-1">
                <h2 class="secondary-font">Delivery Method</h2>
            </div>
            <div fxFlex="100" class="px-3">
                <div class="divider"></div>
            </div> 
            <!-- <div fxFlex="100" class="px-3 mb-4">
                <p class="py-1" *ngIf="OrderDetails.trnsOrder">Delivery Types <span class="text-muted"> {{OrderDetails.trnsOrder.deliveryType }} </span></p>
                <p class="py-1" *ngIf="OrderDetails.trnsOrder">Delivery Charges <span class="text-muted"> {{OrderDetails.trnsOrder.deliverycharges  | currency : 'AED' : 'symbol' : '1.2-2'}} / Delivery in 15 Minutes</span></p>
            </div>  -->
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Delivery Types:</p>
                <p *ngIf="OrderDetails.orderAddress">{{OrderDetails.trnsOrder.deliveryType}}</p>
            </div>
            <!-- <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Delivery Charges:</p>
                <p *ngIf="OrderDetails.orderAddress">AED {{OrderDetails.trnsOrder.deliverycharges  | number : '2.2'}}</p>
            </div> -->

            <div fxFlex="100" class="px-3 mb-2 mt-3">
                <h2 class="secondary-font">Payment Method</h2>
            </div>
            <div fxFlex="100" class="px-3">
                <div class="divider"></div>
            </div> 

            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Payment Types:</p>
                <p *ngIf="OrderDetails.orderAddress">{{OrderDetails.trnsOrder.paymentType}}</p>
            </div>
            <!-- <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Pay Amount:</p>
                <p *ngIf="OrderDetails.orderAddress">AED {{OrderDetails.trnsOrder.netAmount  |  number : '2.2'}}</p>
            </div> -->

            
           
      

            <!-- <div fxFlex="100" class="px-3 mb-2">
                <h2 class="secondary-font">Payment Method</h2>
            </div>
            <div fxFlex="100" class="px-3">
                <div class="divider"></div>
            </div> 
            <div fxFlex="100" class="px-3 mb-4">
                <p class="py-1"><span class="text-muted">Card number:</span><span class="mx-1">XXX-XXX-XXX-7224</span></p>
                <p class="py-1"><span class="text-muted">Cash on Delivery</span>
            </div>  -->
            
             
            
        </div>   

    </div>  
</mat-dialog-content> 