import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'; 
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { AgmCoreModule } from '@agm/core'; 

import { environment } from 'src/environments/environment';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'; 
export function HttpLoaderFactory(httpClient: HttpClient) { 
  return new TranslateHttpLoader(httpClient, environment.url +'/assets/i18n/', '.json');
}
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorI18nService } from './theme/utils/mat-paginator-i18n.service';

import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { AppInterceptor } from './theme/utils/app-interceptor';

import { AppRoutingModule } from './app-routing.module'; 
import { SharedModule } from './shared/shared.module'; 


import { AppComponent } from './app.component';
import { AppSettings } from './app.settings';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component'; 
import { Toolbar1Component } from './theme/components/toolbar1/toolbar1.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';  
import { ContactsComponent } from './theme/components/contacts/contacts.component'; 
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { FooterComponent } from './theme/components/footer/footer.component'; 
import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';
import { CoreModule } from './core/core.module';
import { JwtInterceptor } from './core/interceptor/jwt.interceptor';
import { MaterialTimePickerModule } from '@candidosales/material-time-picker';
import { MomentModule } from 'ngx-moment';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { ThermalPrintModule } from 'ng-thermal-print'; 
///import { AngularFireModule } from '@angular/fire';
//import { environment } from "../environments/environment";


//initializeApp(environment.firebase);
export const config = {
  apiKey: "AIzaSyAioKepyeGO7j_FaXI_QfvUDmQhRSpbwX0",
  authDomain: "tkaff-345011.firebaseapp.com",
  projectId: "tkaff-345011",
  storageBucket: "tkaff-345011.appspot.com",
  messagingSenderId: "606757061093",
  appId: "1:606757061093:web:233d7a316e138ca339ef0c",
  measurementId: "G-EEP40H5WWP"
}

@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    NotFoundComponent,
    UserMenuComponent,  
    ContactsComponent, 
    Toolbar1Component,
    HorizontalMenuComponent,
    VerticalMenuComponent,
    FooterComponent,

    LockScreenComponent
    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }), 
    BrowserAnimationsModule,
    HttpClientModule, 
    NgProgressModule,
    NgProgressHttpModule,
    MaterialTimePickerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAioKepyeGO7j_FaXI_QfvUDmQhRSpbwX0',//'AIzaSyDETJgTlI4Av9LJuyBaqxdFhw1cmzc1qEY',
      //apiKey: 'AIzaSyAjca2rHTJ9z5vDpv6hZpvnUy-2uvkAO5Q',

      // apiKey: 'AIzaSyDu2482VxmlGhAOGmIJZ6wBShRKnduh_X4',

      // apiKey: 'AIzaSyAbhV8E94adf23fuTO8Lvx9ykmqMjZuvqA',
      // apiKey: 'AIzaSyDETJgTlI4Av9LJuyBaqxdFhw1cmzc1qEY',
      libraries: ["places"]
    }), 
    // AngularFireModule.initializeApp(config),
    // AngularFireMessagingModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    
    AppRoutingModule,
    SharedModule,
    //CoreModule
    MomentModule,
    CoreModule,
    ThermalPrintModule,
    FormsModule

  ],
  providers: [ 
    AppSettings,
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    DatePipe,
    { provide: MatPaginatorIntl, useClass: MatPaginatorI18nService },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
