<footer>    
    <div class="px-3">
        <div class="theme-container"> 
            <!-- <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="center center" class="content border-lighter py-5">
                <div fxFlex="100" fxFlex.gt-sm="40" fxLayout="column" fxLayoutAlign="center center"> 
                    <h1 class="secondary-font">Subscribe our Newsletter</h1>
                    <p class="desc">Stay up to date with our latest news and properties</p>
                </div>
                <form [formGroup]="subscribeForm" (ngSubmit)="onSubscribeFormSubmit(subscribeForm.value)" class="custom-form" fxLayout="row" fxFlex="100" fxFlex.gt-sm="42.4" ngClass.sm="mt-2" ngClass.xs="mt-2">
                    <mat-form-field appearance="outline" floatLabel="always" class="subscribe-input" fxFlex>
                        <input matInput autocomplete="off" formControlName="email" placeholder="Your email address...">                      
                    </mat-form-field>
                    <button mat-flat-button type="submit" class="subscribe-btn" color="primary">Subscribe</button>
                </form>
            </div>  -->
            <div class="py-5 content border-lighter"> 
                <div fxLayout="row wrap" fxLayoutAlign="space-between"> 
                    <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="45"> 
                        <a class="logo" routerLink="/"><app-logo></app-logo></a>
                        <p class="mt-4 mb-3 desc">Pocket TKAFF with the TKAFF mobile app. Download our free mobile app and order food from 1000+ restaurants in the UAE. Connect to hundreds of restaurants and order from a variety of cuisines and food through our mobile app. Get exciting offers, view food images, choose your delivery option, place advance order and what not.</p>
                        <p fxLayout="row" fxLayoutAlign="start center" class="mt-2">
                            <mat-icon color="primary">location_on</mat-icon>
                           <a href="https://goo.gl/maps/wLUQnJ7tJY8UGTE17" target="_blank"> <span class="mx-2">SIT Towers, Dubai Silicon Oasis
                            (DSO) Dubai, UAE.</span></a>
                        </p>
                        <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                            <mat-icon color="primary">call</mat-icon>
                           <a href="tel:+97143290898"> <span class="mx-2">+97143290898</span></a>
                        </p>
                        <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                            <mat-icon color="primary">mail_outline</mat-icon>
                            <a href="mailto:info@TKAFF.com">  <span class="mx-2">info@TKAFF.com</span></a><br>
                            <a href="mailto:support@TKAFF.com">   <span class="mx-2">support@TKAFF.com</span></a>
                        </p>
                      
                        <app-social-icons [iconSize]="'lg'" fxLayout="row" fxLayoutAlign="start center" class="desc"></app-social-icons>                    
                    </div>

                    <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="45" ngClass.xs="pt-5"> 
                        <h1 class="secondary-font">Feedbacks</h1>
                        <p class="desc">We want your feedback about everything</p>
                        <form [formGroup]="feedbackForm" (ngSubmit)="onFeedbackFormSubmit(feedbackForm.value)" class="custom-form pt-2">                            
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>E-mail</mat-label> 
                                <input matInput autocomplete="off" formControlName="email">
                                <mat-error *ngIf="feedbackForm.controls.email.errors?.required">Email is required</mat-error>
                                <mat-error *ngIf="feedbackForm.controls.email.hasError('invalidEmail')">Invalid email address</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Message for us</mat-label> 
                                <textarea matInput formControlName="message" rows="6"></textarea> 
                                <mat-error *ngIf="feedbackForm.controls.message.errors?.required">Message is required</mat-error>
                            </mat-form-field>
                            <div class="w-100 text-center">
                                <button mat-flat-button color="primary" class="uppercase" type="submit">Submit</button> 
                            </div> 
                        </form> 
                    </div>
 
                    <div fxFlex="100" fxFlex.gt-sm="30" ngClass.lt-md="pt-5"> 
                        <h1 class="secondary-font mb-3">Our location</h1>   
                        <agm-map [latitude]="25.1178369" [longitude]="55.3906301" [zoom]="zoom" [mapTypeControl]="false" [styles]="mapStyles">
                            <agm-marker [latitude]="25.1178369" [longitude]="55.3906301" [markerDraggable]="false"></agm-marker>
                        </agm-map> 
                    </div>
                </div>  
            </div> 
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="copyright py-2">
                <p ngClass.xs="mt-1">Copyright © Tkaff {{ currentYear }} All Rights Reserved</p>  
                <!-- <p> <a mat-button href="http://shriaura.com/" target="_blank"> ShriAura Technologies</a></p> -->
            </div> 
        </div>
    </div>      
</footer>