import { Component, Inject, PLATFORM_ID,ChangeDetectionStrategy  } from '@angular/core';
import { Settings, AppSettings } from './app.settings';
import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from 'src/environments/environment';
import { OneSignal } from 'onesignal-ngx';
import { AppService } from './app.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush

})
export class AppComponent {
   
  public settings: Settings;
  constructor(public appSettings:AppSettings, private oneSignal: OneSignal,
              public router:Router,
              @Inject(PLATFORM_ID) private platformId: Object,
              public appService : AppService,
              public translate: TranslateService){
    this.settings = this.appSettings.settings;
    translate.addLangs(['en','de','fr','ru','tr']);
    translate.setDefaultLang('en'); 
    translate.use('en');
    // this.oneSignal.init({
    //   appId: "62a6118d-8008-488e-b1b8-8bd74cc88849",
    // }).then((res) => {
    // console.log('onesignal',res)
    // });
    // this.oneSignal.getUserId().then((user)=>{
    //   console.log('onesignal',user)
    //   if(user && localStorage.getItem("vendorid") ){
    //     localStorage.setItem("web_token",user);
    //     let data ={
    //       "VendorId" : localStorage.getItem("vendorid"),
    //       "MachineId" : user
    //     }
    //     this.appService.UpdateWebToken(data).subscribe((res:any)=>{
    //       console.log('onesignal',res)
    //     })
    //   }
      
    // })
  }

  title = 'Tkaff-notification';
  message:any = null;

  ngOnInit(): void {
   
  }

  ngAfterViewInit(){ 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {   
        setTimeout(() => {
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0,0);
          }
        }); 
      }            
    });    
  }

  // requestPermission() {
  //   const messaging = getMessaging();
  //   getToken(messaging, 
  //    { vapidKey: "BBBWI4YfwRv-vWK0GrQ2RYAaatPfFzll8WhGWqbqd3WXhe9cSQUlDClB-pI68YKWkn0cxtvCVK3liteMhFc0Z-E"}).then(
  //      (currentToken) => {
  //        if (currentToken) {
  //          console.log("Hurraaa!!! we got the token.....");
  //          console.log(currentToken);
  //        } else {
  //          console.log('No registration token available. Request permission to generate one.');
  //        }
  //    }).catch((err) => {
  //       console.log('An error occurred while retrieving token. ', err);
  //   });
  // }
  // listen() {
  //   const messaging = getMessaging();
  //   onMessage(messaging, (payload) => {
  //     console.log('Message received. ', payload);
  //     this.message=payload;
  //   });
  // }

}
