import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, Order, Category, VendorBranch, RestaurantType, UserRoles } from 'src/app/app.models';
import { vendor } from 'src/app/core/models/register'
import { AppSettings } from 'src/app/app.settings';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/admin/users/user.model'
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from './shared/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './shared/alert-dialog/alert-dialog.component';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/service/auth.service';


export class Data {
  constructor(
    public categories: Category[],
    public resturanttype: RestaurantType[],
    public userrole: UserRoles[],
    public cartList: MenuItem[],
    public orderList: Order[],
    public favorites: MenuItem[],
    public totalPrice: number,
    public totalCartCount: number
  ) { }
}

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public Data = new Data(
    [], // categories
    [], // resturanttyp
    [], // role
    [], // cartList
    [], // orderList
    [], // favorites
    0, // totalPrice
    0 //totalCartCount
  );

  public url = environment.url + '/assets/data/';

  //change Password
  private ChangePassword = environment.API_URL + "account/password-change";
  private ConfigFile ="/assets/config/config.json";
  //WebTokenNotification
  private webTokenId =environment.API_URL+"master/vendor/update-vendor-machineid";


  //Vendor Dashboad
  private Vendor_Get_Latest_order =environment.API_URL+"vendordashboard/get-last-10-orders"
  private Vendor_Get_order_count =environment.API_URL+"vendordashboard/get-order-count"
  private Vendor_Get_Month_order_count =environment.API_URL+"vendordashboard/get-monthorder-count"
  private Vendor_Get_Today_order_count =environment.API_URL+"vendordashboard/get-todayorder-count"
  private Vendor_Get_Customer_count =environment.API_URL+"vendordashboard/get-customer-count"
  private Vendor_Get_Order_Value =environment.API_URL+"vendordashboard/get-ordervalues"
  private Vendor_Get_Tax_Value =environment.API_URL+"vendordashboard/get-taxvalues"
  private Vendor_Get_Daywise_Order =environment.API_URL+"vendordashboard/get-daywise-order-summary"
  private Vendor_Get_Category_Order =environment.API_URL+"vendordashboard/get-category-wise-order"
  private Vendor_Dashboard_Count =environment.API_URL+"vendordashboard/get-order-summary" 

  //ResturantType
  private Resturant_Type = environment.API_URL + "account/get-usertype/VendorType";

  //Role
  private _UserRole = environment.API_URL + "master/user/get-userrole";

  //Vendor Type
  private _VendorType = environment.API_URL + "lov/get-by-type/";


  //Category
  private _Category_List = environment.API_URL + "master/category/get-categorylist";
  private _Category_ADD = environment.API_URL + "master/category/save-category";
  private _Category_Move = environment.API_URL + "master/category/save-category-order";
  private _Import_Default_Menu = environment.API_URL + "master/menu/import-menulist/";
  private _Category_Remove = environment.API_URL + "master/category/remove/";
  private _Category_File_upload = environment.API_URL + "master/category/upload-file";
 
  //Menu
  private Menu_List_All = environment.API_URL + "master/menu/get-menulist";
  private Menu_List_By_Category = environment.API_URL + "master/menu/get-by-category/";
  private Menu_ADD = environment.API_URL + "master/menu/save-menuitem";
  private Menu_By_Id = environment.API_URL + "master/menu/get-info/";
  private Menu_Remove = environment.API_URL + "master/menu/remove/";
  private Menu_Image_Upload = environment.API_URL + "master/menu/upload-file";
  //submenuitems
  private SubMenu_Item = environment.API_URL + "master/menu/get-menu-subitems/";
  private Save_SubMenu_Item = environment.API_URL + "master/menu/save-sub-menuitem";
  private Remove_SubMenu_Item = environment.API_URL + "master/menu/remove-submenu/";


  //Vendor-Branch
  private Vendor_Branch_List_All = environment.API_URL + "master/branch/get-branchlist";
  private Vendor_Branch_Info = environment.API_URL + "master/branch/get-details/";
  private Vendor_Branch_Add = environment.API_URL + "master/branch/save-branch";
  private Vendor_Branch_Remove = environment.API_URL + "master/branch/remove/";


  //USER
  private getUsersList = environment.API_URL + "master/user/get-users";
  private addUsers = environment.API_URL + "master/user/create-user";
  private updateUsers =environment.API_URL+"master/user/update-user";
  private removeUsers = environment.API_URL + "master/user/remove/";
  private UsersUpload = environment.API_URL + "master/user/photo-upload";

  //Vendor

  private getVendorList =environment.API_URL+"master/vendor/get-vendorlist";
  private ActivateVendor =environment.API_URL+"master/vendor/vendor-activation/";
  private DeactivateVendor =environment.API_URL+"master/vendor/vendor-deactivation/";
  private viewVendor=environment.API_URL+"master/vendor/get-vendorinfo-byadmin/";

  //Customer
  private Customerlist=environment.API_URL+"master/customer/query-customer";

  //GeoCityList
  private Geo_City_List = environment.API_URL + "master/geo/get-city-list";

  //VendorOrder
  private getVendorOrderList = environment.API_URL + "trans/orders/get-orders-byvendor";
  private getVendorAllOrderList = environment.API_URL + "trans/orders/get-orders-byvendor-all";
  private getVendorOrdersByStatus = environment.API_URL + "trans/orders/get-orders-bystatus/";
  private getVendorAllOrdersByStatus = environment.API_URL + "trans/orders/get-orders-dashboard/";
  private getvendorinfo = environment.API_URL + "master/vendor/prelogin/get-vendorinfo/";
    
  //OrderInfo
  private getVendorOrderInfo = environment.API_URL + "trans/orders/get-orderinfo/";

  //OrderTypeMealplan
  private getVendorOrderTypeMealplan = environment.API_URL + "trans/orders/get-orders-byvendor-mealplan";
  
  //OrderTypesubcriptionplan
  private getVendorOrderTypeSubcriptionplan = environment.API_URL + "trans/orders/get-orders-byvendor-subscription";
  
  //OrderTypeMealplanInfo
  private getVendorOrderTypeSubcriptionplanInfo = environment.API_URL + "trans/orders/get-schorderinfo/";

  //OrderTypesubcriptionplanInfo
  private getVendorOrderTypeMealplanInfo = environment.API_URL + "trans/orders/get-mealorderinfo/";

  //VendorMealOrder
  private getVendorMealPlanCombo = environment.API_URL + "master/mealplancombo/get-mealplan/";
  private saveVendorMealPlanCombo = environment.API_URL + "master/mealplancombo/save-mealplan/";
  private getVendorMealPlanOrder = environment.API_URL + "trans/mealplan/get-mealplan-byvendor";
  
  //VendorOrderMeal
  private getVendorMealPlan_Order = environment.API_URL + "trans/mealplan/get-mealplan-byvendor";

  //VendorMealOrder
  private getVendorMealPlanOrderInfo = environment.API_URL + "trans/mealplan/get-mealplan-byid/";

  //VendorScheduleOrder
   private getVendorSchedule = environment.API_URL + "trans/subscription/get-orders-byvendor";

    //VendorScheduleOrder
  private getVendorScheduleOrderInfo = environment.API_URL + "trans/subscription/get-orderinfo/";

  //getVendorProfile
  private getVendorProfileInfo = environment.API_URL + "master/vendor/get-profile";

  //save & edit vendor config
  private saveVendorProfile = environment.API_URL + "master/vendor/update-vendor";

  //save & edit vendor config
  private saveVendorConfig = environment.API_URL + "master/vendor/save-vendorconfig";

  //vendor file upload
  private _vendor_Upload = environment.API_URL + "master/vendor/upload-file";

  //Vendor address
  private _vendor_Address = environment.API_URL + "master/vendor/save-vendoraddress";

  //Edit & save order
  private editOrder = environment.API_URL + "trans/orders/save-order";

  //Cancel order
  private cancelOrder = environment.API_URL + "trans/orders/cancel-order/";

  //Save Order Items
  private Save_Order_Items = environment.API_URL + "trans/orders/save-order-detail";

  //Save Order Address 
  private Save_Order_Address = environment.API_URL + "trans/orders/save-order-address";

  //Edit Payment
  private Edit_Payment = environment.API_URL + "trans/orderpayment/save-neworder-payment";

  //Order Status Change
  private Order_status_update = environment.API_URL + "trans/orders/order-status-update/";
  private Order_subscription_status_update = environment.API_URL + "trans/subscription/order-status-update/";
  private Order_mealplan_status_update = environment.API_URL + "trans/mealplan/order-status-update/";
  
  //Start Delivery For Road Side Pickup / COD
  private Order_status_start_delivery = environment.API_URL + "trans/delivery/startdelivery";

  //Delivery Details
  private Delivery_details = environment.API_URL + "trans/delivery/get-deliveryinfo-byOrder/";


  //Payment Received on Delivery time
  private Payment_Received = environment.API_URL + "trans/orderpayment/payment-complete";

  //ViewVendorReview
  private View_Vendor_Review = environment.API_URL + "trans/vendorreview/get-review-byvendor/";

  //RejectReview
  private Reject_Review = environment.API_URL + "trans/vendorreview/reject-review/"

  //Ewallet Summary
  private Ewallet_Summary = environment.API_URL + "trans/ewallet/get-customer-summary";
  private Ewallet_Topup = environment.API_URL + "trans/ewallet/ewallet-topup-byvendor";
  private Ewallet_Refund = environment.API_URL + "trans/ewallet/ewallet-refund";
  private Ewallet_Ledger = environment.API_URL + "trans/ewallet/get-Ledger";


  //Customerbymobileno
  private CustomerByMobileno = environment.API_URL + "master/customer/get-customerinfo/";


  //ReviewOrderReply
  private Review_Order_Reply = environment.API_URL + "trans/menureview/reply-review";


  //Admin Vendor Order
  private Admin_Vendor_Order_Listbyiddate = environment.API_URL + "trans/orders/get-orders-bysuperadmin/"

  //Admin Testimonials
  private Admin_Get_Testimonials = environment.API_URL + "trans/testimonials/get-testimonials"

  //Admin Save Testimonials
  private Admin_Save_Testimonials = environment.API_URL + "trans/testimonials/save-testimonial"

  //Admin Deactivate Testimonials
  private Admin_Deactivate_Testimonials = environment.API_URL + "trans/testimonials/save-testimonial"

  //Admin Promotion
  private Admin_Get_Promotion = environment.API_URL + "promo/get-live-promo"
  private Admin_Remove_Promotion = environment.API_URL + "promo/promo-deactivate/"

  //save Promotion
  private Admin_Save_Promotion = environment.API_URL + "promo/vendor-promo-upload"

  //Meal plan
  private Get_Meal_Plan = environment.API_URL + "master/mealplan/get-mealplan/"
  private Save_Meal_Plan = environment.API_URL + "master/mealplan/save-mealplan"

  //Report Settlement
  private Get_Settlement = environment.API_URL + "trans/settlement/get-settlementlist"
  private Get_Settlement_info = environment.API_URL + "trans/settlement/get-settlementinfo/"
  private Settlement_Process = environment.API_URL + "trans/settlement/settlement-process"

  private Admin_Datewise_Summary = environment.API_URL + "report/tkaff/get-datewise-summary"
  private Admin_Vendor_Summary = environment.API_URL + "report/tkaff/get-vendorwise-summary"
  private Admin_Datewise_Transaction = environment.API_URL + "report/tkaff/get-date-wise-transacion"





  //Vendor Report
  private Vendor_DailySale = environment.API_URL + "report/vendor/get-dailysales"
  private Vendor_DailySummary = environment.API_URL + "report/vendor/get-daywisesummary"
  private Vendor_MonthlySummary = environment.API_URL + "report/vendor/get-monthwisesummary"
  private Vendor_Menuwisesale = environment.API_URL + "report/vendor/get-menuitemWisesales"


  //DownloadURL
  private submitPartner = environment.API_URL+"general/appdownload"
  


  

  constructor(
    public http: HttpClient,
    private datePipe: DatePipe,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public appSettings: AppSettings,
    public translateService: TranslateService,
    private authenticationService: AuthService,
  ) { }

  //changepassword
  passwordchange(data: any) {
    return this.http.post(this.ChangePassword, data, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  getConfig() {
    return this.http.get(this.ConfigFile, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }
  //WebTokenUpdate
  UpdateWebToken(data: any) {
    return this.http.post(this.webTokenId, data, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }
  //VendorDashboard
  GetOrderValue(){
    //return this.http.get(this.Vendor_Get_Order_Value, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
    return this.http.get(this.Vendor_Get_Order_Value);
  }
  GetTaxValue(){
    //return this.http.get(this.Vendor_Get_Tax_Value, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
    return this.http.get(this.Vendor_Get_Tax_Value);
  }
  GetCustomerCount(){
    //return this.http.get(this.Vendor_Get_Customer_count, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
    return this.http.get(this.Vendor_Get_Customer_count);
  }
  GetTodayOrderCount(){
    return this.http.get(this.Vendor_Get_Today_order_count);
  }
  GetMonthlyOrderCount(){
    return this.http.get(this.Vendor_Get_Month_order_count);
  }
  GetOrderCount(){
    return this.http.get(this.Vendor_Get_order_count);
  }
  GetLast10Order(){
    return this.http.get(this.Vendor_Get_Latest_order);
  }
  GetDayWiseOrder(){
    return this.http.get(this.Vendor_Get_Daywise_Order);
  }
  GetCategoryWiseOrder(){
    return this.http.get(this.Vendor_Get_Category_Order);
  }

  GetDashboardCount(){
    return this.http.get(this.Vendor_Dashboard_Count);
  }
  //Get Vendor Profile
  OnGetVendorProfile() {
    //return this.http.get(this.getVendorProfileInfo, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
    return this.http.get(this.getVendorProfileInfo);
  }

  //edit & profile
  VendorSaveProfile(profile: any) {
    return this.http.post(this.saveVendorProfile, profile, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //save & edit vendor confiig
  VendorsaveConfig(config: any) {
    return this.http.post(this.saveVendorConfig, config, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //vendor upload
  OnVendorUpload(formdata: any) {
    // console.log(formdata.getAll('File'))
    // console.log(formdata.getAll('Id'))
    // console.log(formdata.getAll('FileType'))
    return this.http.post(this._vendor_Upload, formdata, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  OnVendorAddress(v: any) {
    return this.http.post(this._vendor_Address, v, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  //GeoCityList
  public getGeoCityList() {
    return this.http.get(this.Geo_City_List, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }


  //get Vendor Type
  public getVendorType(type: string) {
    return this.http.get(this._VendorType + type, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //get resturant all type
  public getResturantType(): Observable<RestaurantType[]> {
    return this.http.get<RestaurantType[]>(this.Resturant_Type);
  }

  //get UserRole
  public getUserRole(): Observable<UserRoles[]> {
    return this.http.get<UserRoles[]>(this._UserRole, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  //Menu List By Category
  public getMenuItemByCategoryId(id: number): Observable<MenuItem[]> {
    return this.http.get<MenuItem[]>(this.Menu_List_By_Category + id, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  //Add Menu 
  AddNewMenu(menu: any) {
    menu.availTimeStart = this.datePipe.transform(menu.AvailTimeStart, "H:mm")
    menu.availTimeEnd = this.datePipe.transform(menu.AvailTimeEnd, "H:mm")
    return this.http.post(this.Menu_ADD, menu, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //All Menu List
  public getMenuItems(): Observable<MenuItem[]> {
    return this.http.get<MenuItem[]>(this.Menu_List_All, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  //Menu item by id
  public getMenuItemById(id: number): Observable<MenuItem> {
    return this.http.get<MenuItem>(this.Menu_By_Id + id, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  //RemoveMenu
  RemoveMenu(id: number) {
    return this.http.post(this.Menu_Remove + id, '', { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //MenuImageUpload
  MenuImageUpload(formdata: any) {
    return this.http.post(this.Menu_Image_Upload, formdata, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //GetsubItems
  public getSubMenuItem(id: number): Observable<MenuItem[]> {
    return this.http.get<MenuItem[]>(this.SubMenu_Item + id, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  //Add Menu 
  AddSubItemMenu(menu: any) {
    return this.http.post(this.Save_SubMenu_Item, menu, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //RemoveSubItemMenu
  RemoveSubItemMenu(id: number) {
    return this.http.post(this.Remove_SubMenu_Item + id, '', { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  public getSpecialMenuItems(): Observable<MenuItem[]> {
    return this.http.get<MenuItem[]>(this.url + 'special-menu-items.json');
  }

  public getBestMenuItems(): Observable<MenuItem[]> {
    return this.http.get<MenuItem[]>(this.url + 'best-menu-items.json');
  }

  //get categories list
  public getCategories(): Observable<Category[]> {
    return this.http.get<Category[]>(this._Category_List, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  //Import Default Menu
  ImportDefaultMenu(vendorid: any) {
    // cat.availTimeStart = this.datePipe.transform(cat.availTimeStart, "H:mm")
   //  cat.availTimeEnd = this.datePipe.transform(cat.availTimeEnd, "H:mm")
     return this.http.post(this._Import_Default_Menu + vendorid,'', { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
   }

  //AddCategory
  AddCategory(cat: Category) {
   // cat.availTimeStart = this.datePipe.transform(cat.availTimeStart, "H:mm")
  //  cat.availTimeEnd = this.datePipe.transform(cat.availTimeEnd, "H:mm")
    return this.http.post(this._Category_ADD , cat, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //RemoveCategory
  RemoveCategory(id: number) {
    return this.http.post(this._Category_Remove + id, '', { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //MoveCategory
  MoveCategory(data: any) {
    return this.http.post(this._Category_Move ,data, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  UploadCategoryImage(formdata: any) {
    return this.http.post(this._Category_File_upload, formdata, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //VendorBranchAllList
  public getAllVendorBranch(): Observable<VendorBranch[]> {
    return this.http.get<VendorBranch[]>(this.Vendor_Branch_List_All, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  //AddCategory
  AddVendorBranch(vb: VendorBranch) {
    return this.http.post(this.Vendor_Branch_Add, vb, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //RemoveVendorBranch
  RemoveVendorBranch(id: number) {
    return this.http.post(this.Vendor_Branch_Remove + id, '', { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }


  GetCustomerList(cus:any){
    return this.http.post(this.Customerlist, cus, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }


  public getHomeCarouselSlides() {
    return this.http.get<any[]>(this.url + 'slides.json');
  }

  public getReservations() {
    return this.http.get<any[]>(this.url + 'reservations.json');
  }

  public getOrders() {
    return this.http.get<any[]>(this.url + 'orders.json');
  }


  //getVendorOrder
  public getVendorOrders():Observable<any[]> {
    return this.http.get<any[]>(this.getVendorOrderList, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }
  public getVendorAllOrders():Observable<any[]> {
    return this.http.get<any[]>(this.getVendorAllOrderList, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }
  public getVendorOrderByStatus(status:string) {
    return this.http.get<any[]>(this.getVendorOrdersByStatus + status, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }
  public getVendorAllOrderByStatus(status:string) {
    return this.http.get<any[]>(this.getVendorAllOrdersByStatus + status, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }
  
  //getVendorOrder
  public getVendorMealOrdersType():Observable<any[]> {
    return this.http.get<any[]>(this.getVendorOrderTypeMealplan, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

   //getVendorOrderMelaplanInfo
   public getVendorMealOrdersTypeInfo(ord:any) :Observable<any[]> {
    return this.http.get<any[]>(this.getVendorOrderTypeMealplanInfo+ord, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  //getVendorOrder
  public getVendorSubcriptionOrdersType() :Observable<any[]>{
    return this.http.get<any[]>(this.getVendorOrderTypeSubcriptionplan, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  //getVendorOrder
  public getVendorSubcriptionOrdersTypeInfo(ord:any):Observable<any[]> {
    return this.http.get<any[]>(this.getVendorOrderTypeSubcriptionplanInfo+ord, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  //Vendororder Info
  public getVendorOrdersInfo(id: number) {
    return this.http.get(this.getVendorOrderInfo + id, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });

  }

  //GetVendorMealplanorder
  public getMealPlanOrder():Observable<any[]>{
    return this.http.get<any[]>(this.getVendorMealPlanOrder, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }
  public getMealPlanCombo(id:number){
    return this.http.get<any[]>(this.getVendorMealPlanCombo + id, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }
  
  //GetVendorOrder
  public getMealPlabynOrder():Observable<any[]>{
    return this.http.get<any[]>(this.getVendorMealPlan_Order, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  //VendororderMealorder Info
  public getMealOrderInfo(id: number) {
    return this.http.get(this.getVendorMealPlanOrderInfo + id, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });

  }

  //GetVendorSchedule
  public getScheduleOrder():Observable<any[]>{
    return this.http.get<any[]>(this.getVendorSchedule, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

   //VendororderScheduleInfo
   public getScheduleOrderInfo(id: number) {
    return this.http.get(this.getVendorScheduleOrderInfo + id, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  //Order Status update
  public OrderStatus(id: number, status: any) {
    return this.http.post(this.Order_status_update + id + '/' + status, '', { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  //Order Status update
  public OrderSubscriptionStatus(id: number, status: any) {
    return this.http.post(this.Order_subscription_status_update + id + '/' + status, '', { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }
  //Order Status update
  public OrderMealPlanStatus(id: number, status: any) {
    return this.http.post(this.Order_mealplan_status_update + id + '/' + status, '', { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }
  
  //Cancel Order
  public CancelOrder(id: number) {
    return this.http.post(this.cancelOrder + id, '', { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  //Editer Order
  public EditerOrder(order: any) {
    return this.http.post(this.editOrder, order, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //save-item-re-order
  public saveOrderDetails(item: any) {
    return this.http.post(this.Save_Order_Items, item, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //order save address
  public saveOrderAddress(order: any) {
    return this.http.post(this.Save_Order_Address, order, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }


  //Edit Payment 
  public edit_order_payment(pay: any) {
    return this.http.post(this.Edit_Payment, pay, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //Payment Receieved on Delivery Time
  public OrderPayementReceived(data: any) {
    return this.http.post(this.Payment_Received, data, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //Order Status - start Delivery
  public OrderStatusStartDelivery(data: any) {
    return this.http.post(this.Order_status_start_delivery, data, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //Delivery Details
  public getDeliveryDetailsbyOrderID(id: number) {
    return this.http.get(this.Delivery_details + id, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });

  }

  //View Vendor Review
  public getVendorReview(id: number) {
    return this.http.get(this.View_Vendor_Review + id, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  //Reject Review
  public ReviewReject(id: number) {
    return this.http.post(this.Reject_Review + id, '', { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  //Reply Order Review
  public ReviewOrderReply(reply:any){
    return this.http.post(this.Review_Order_Reply , reply, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }



  //ALL USer
  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.getUsersList, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  //adduser
  addUser(user: any) {
    console.log(user)
    return this.http.post(this.addUsers, user, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  updateUser(user:any){
    return this.http.post(this.updateUsers, user,{ headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() }} );
  }

  //removeuser
  removeUser(id: number) {
    return this.http.post(this.removeUsers + id, '', { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //Uploaduser
  userUpload(formdata:any){
    return this.http.post(this.UsersUpload, formdata, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
  }

  //AllVendor
  getVendor(): Observable<vendor[]> {
    return this.http.get<vendor[]>(this.getVendorList, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });

  }

  //Ewallet Summary
  getEwalletSummary() {
    return this.http.get(this.Ewallet_Summary, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //Ewallet Ledger
  getEwalletLedger(eWall:any) {
    return this.http.post(this.Ewallet_Ledger, eWall,{ headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //TopUp EWallet
  TopupEwallet(top:any){
    return this.http.post(this.Ewallet_Topup, top,{ headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //Refund Ewallet
  RefundEwallet(ref:any){
    return this.http.post(this.Ewallet_Refund, ref,{ headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

  //GetCustomerByMobile
  getCustomerByMobile(mob:any){
    return this.http.get(this.CustomerByMobileno+mob,{ headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
  }

//viewvendor
viewVendorinfo(p_VendorId:number){
  return this.http.get(this.viewVendor + p_VendorId,{ headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });

}

//PreAuth getvendorinfo
Preauthgetvendorinfo(p_VendorId:number){
  return this.http.get(this.getvendorinfo + p_VendorId,{ headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
}


//ActivateVendor
ActivateVendors(p_VendorId:number){
  return this.http.post(this.ActivateVendor + p_VendorId, '', { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })

}

//DeactiveVendor
DeactivateVendors(p_VendorId:number){
  return this.http.post(this.DeactivateVendor + p_VendorId, '', { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
}

//AdminVendorOrderList
GetSuperAdminOrderListByIdDate(id:number,date:any){
  return this.http.get(this.Admin_Vendor_Order_Listbyiddate + id+'/'+date,{ headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
}

//Admin_Testimonials
GetAdminTestimonials(){
  return this.http.get(this.Admin_Get_Testimonials,{ headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
}

//Admin Save Testimonials
SaveTestimonials(rev:any){
  return this.http.post(this.Admin_Save_Testimonials, rev,{ headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
}

//Admin DeActivate Testimonials
DeactivateTestimonials(id:number){
  return this.http.post(this.Admin_Deactivate_Testimonials + id, '', { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
}

//Admin Get Promotion
GetPromotion(){
  return this.http.get(this.Admin_Get_Promotion,{ headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
}

//Remove Promotion
RemovePromotion(id:number){
  return this.http.post(this.Admin_Remove_Promotion+id,'',{ headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
}


//Admin save Promotion
SavePromotion(formdata:any){
  return this.http.post(this.Admin_Save_Promotion,formdata,{ headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
}

//Get Meal plan  
GetMealPlan(id: number) {
  return this.http.get(this.Get_Meal_Plan + id,  { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
}

//Meal Plan Save
SaveMEalPlan(mealplan:any){
  return this.http.post(this.Save_Meal_Plan, mealplan, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
}
SaveMEalPlanCombo(mealplan:any){
  return this.http.post(this.saveVendorMealPlanCombo, mealplan, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
}

//GetSettlementReport
GetSettlement(){
  return this.http.get(this.Get_Settlement,{ headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
}

//GetSettlementInfo
GetSettlementInfo(id:number){
  return this.http.get(this.Get_Settlement_info+id,{ headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } });
}

//SettlementProcess
SettlementProcess(set:any){
  return this.http.post(this.Settlement_Process, set, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
}

//AppDownload
AppDowmload(pat:any){
  return this.http.post(this.submitPartner, pat, { headers: { Authorization: 'Bearer ' + this.authenticationService.getToken() } })
}

//Vendor Report
getDailySale(data:any){
  return this.http.post(this.Vendor_DailySale,data)
}
getDailySummary(data:any){
  return this.http.post(this.Vendor_DailySummary,data)
}
getMonthlySummary(){
  return this.http.post(this.Vendor_MonthlySummary,'')
}
getMenuWiseSale(data:any){
  return this.http.post(this.Vendor_Menuwisesale,data)
}


//AdminReport
getAdminDateSummary(data:any){
  return this.http.post(this.Admin_Datewise_Summary,data)
}
getAdminVendorSummary(data:any){
  return this.http.post(this.Admin_Vendor_Summary,data)
}
getAdminDateTransaction(data:any){
  return this.http.post(this.Admin_Datewise_Transaction,data)
}





  public getGUID() {
    let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
      .replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      })
      .toLowerCase();
    return guid;
  }

  public addToCart(menuItem: MenuItem, component: any) {
    if (!this.Data.cartList.find((item) => item.id == menuItem.id)) {
      menuItem.cartCount = menuItem.cartCount ? menuItem.cartCount : 1;
      this.Data.cartList.push(menuItem);
      this.calculateCartTotal();
      if (component) {
        this.openCart(component);
      } else {
        this.snackBar.open(
          'The menu item "' + menuItem.menuName + '" has been added to cart.',
          '×',
          {
            verticalPosition: 'top',
            duration: 3000,
            direction: this.appSettings.settings.rtl ? 'rtl' : 'ltr',
            panelClass: ['success'],
          }
        );
      }
    }
  }

  public openCart(component: any) {
    this.bottomSheet
      .open(component, {
        direction: this.appSettings.settings.rtl ? 'rtl' : 'ltr',
      })
      .afterDismissed()
      .subscribe((isRedirect) => {
        if (isRedirect) {
          window.scrollTo(0, 0);
        }
      });
  }

  public calculateCartTotal() {
    this.Data.totalPrice = 0;
    this.Data.totalCartCount = 0;
    this.Data.cartList.forEach((item) => {
      let price = 0;
      if (item.discount) {
        price = item.rate - item.rate * (item.discount / 100);
      } else {
        price = item.rate;
      }
      this.Data.totalPrice = this.Data.totalPrice + price * item.cartCount;
      this.Data.totalCartCount = this.Data.totalCartCount + item.cartCount;
    });
  }

  public addToFavorites(menuItem: MenuItem) {
    let message: string, status: string;
    if (this.Data.favorites.find((item) => item.id == menuItem.id)) {
      message =
        'The menu item "' + menuItem.menuName + '" already added to favorites.';
      status = 'error';
    } else {
      this.Data.favorites.push(menuItem);
      message =
        'The menu item "' + menuItem.menuName + '" has been added to favorites.';
      status = 'success';
    }
    this.snackBar.open(message, '×', {
      verticalPosition: 'top',
      duration: 3000,
      direction: this.appSettings.settings.rtl ? 'rtl' : 'ltr',
      panelClass: [status],
    });
  }

  public openDialog(component: any, data: any, panelClass: any) {
    return this.dialog.open(component, {
      data: data,
      panelClass: panelClass,
      autoFocus: false,
      direction: this.appSettings.settings.rtl ? 'rtl' : 'ltr',
    });
  }

  public openConfirmDialog(title: string, message: string) {
    const dialogData = new ConfirmDialogModel(title, message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });
    return dialogRef;
  }

  public openAlertDialog(message: string) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      maxWidth: '400px',
      data: message,
    });
    return dialogRef;
  }

  public makeReservation(
    dialogComponent: any,
    data: any,
    onDialog: boolean = false
  ) {
    if (onDialog) {
      const dialogRef = this.openDialog(dialogComponent, null, 'theme-dialog');
      dialogRef.afterClosed().subscribe((data) => {
        if (data) {
          this.showReservationMessage(data);
        }
      });
    } else {
      this.showReservationMessage(data);
    }
  }
  private showReservationMessage(data: any) {
    this.snackBar.open(
      'Dear ' +
      data.fullName +
      ', thank you for your reservation! Your reservation at Popino on the ' +
      this.datePipe.transform(data.date, 'dd-MM-yyyy') +
      ' at ' +
      data.time +
      ' for ' +
      data.guests +
      ' people will review by our team and someone will be in touch soon.',
      '×',
      { panelClass: 'success', verticalPosition: 'top', duration: 9000 }
    );
  }

  public getTranslateValue(key: string, param: string = '') {
    let value = null;
    this.translateService
      .get(key, { param: param })
      .subscribe((res: string) => {
        value = res;
      });
    return value;
  }

  public filterData(
    data: any,
    categoryId: number,
    sort?: string,
    page?: number,
    perPage?: number
  ) {
    if (categoryId) {
      data = data.filter((item: any) => item.categoryId == categoryId);
    }

    //for show more properties mock data
    // for (var index = 0; index < 2; index++) {
    //   data = data.concat(data);
    // }

    this.sortData(sort, data);
    return this.paginator(data, page, perPage);
  }

  public sortData(sort?: string, data?: any) {
    if (sort) {
      switch (sort) {
        case 'Popular':
          data = data.sort((a: any, b: any) => {
            if (
              a.ratingsValue / a.ratingsCount <
              b.ratingsValue / b.ratingsCount
            ) {
              return 1;
            }
            if (
              a.ratingsValue / a.ratingsCount >
              b.ratingsValue / b.ratingsCount
            ) {
              return -1;
            }
            return 0;
          });
          break;
        case 'Price (Low to High)':
          data = data.sort((a: any, b: any) => {
            if (a.price > b.price) {
              return 1;
            }
            if (a.price < b.price) {
              return -1;
            }
            return 0;
          });
          break;
        case 'Price (High to Low)':
          data = data.sort((a: any, b: any) => {
            if (a.price < b.price) {
              return 1;
            }
            if (a.price > b.price) {
              return -1;
            }
            return 0;
          });
          break;
        default:
          break;
      }
    }
    return data;
  }

  public paginator(items: any, page?: any, perPage?: any) {
    var page = page || 1,
      perPage = perPage || 4,
      offset = (page - 1) * perPage,
      paginatedItems = items.slice(offset).slice(0, perPage),
      totalPages = Math.ceil(items.length / perPage);
    return {
      data: paginatedItems,
      pagination: {
        page: page,
        perPage: perPage,
        prePage: page - 1 ? page - 1 : null,
        nextPage: totalPages > page ? page + 1 : null,
        total: items.length,
        totalPages: totalPages,
      },
    };
  }

  public getCountries() {
    return [
      { name: 'Afghanistan', code: 'AF' },
      { name: 'Aland Islands', code: 'AX' },
      { name: 'Albania', code: 'AL' },
      { name: 'Algeria', code: 'DZ' },
      { name: 'American Samoa', code: 'AS' },
      { name: 'AndorrA', code: 'AD' },
      { name: 'Angola', code: 'AO' },
      { name: 'Anguilla', code: 'AI' },
      { name: 'Antarctica', code: 'AQ' },
      { name: 'Antigua and Barbuda', code: 'AG' },
      { name: 'Argentina', code: 'AR' },
      { name: 'Armenia', code: 'AM' },
      { name: 'Aruba', code: 'AW' },
      { name: 'Australia', code: 'AU' },
      { name: 'Austria', code: 'AT' },
      { name: 'Azerbaijan', code: 'AZ' },
      { name: 'Bahamas', code: 'BS' },
      { name: 'Bahrain', code: 'BH' },
      { name: 'Bangladesh', code: 'BD' },
      { name: 'Barbados', code: 'BB' },
      { name: 'Belarus', code: 'BY' },
      { name: 'Belgium', code: 'BE' },
      { name: 'Belize', code: 'BZ' },
      { name: 'Benin', code: 'BJ' },
      { name: 'Bermuda', code: 'BM' },
      { name: 'Bhutan', code: 'BT' },
      { name: 'Bolivia', code: 'BO' },
      { name: 'Bosnia and Herzegovina', code: 'BA' },
      { name: 'Botswana', code: 'BW' },
      { name: 'Bouvet Island', code: 'BV' },
      { name: 'Brazil', code: 'BR' },
      { name: 'British Indian Ocean Territory', code: 'IO' },
      { name: 'Brunei Darussalam', code: 'BN' },
      { name: 'Bulgaria', code: 'BG' },
      { name: 'Burkina Faso', code: 'BF' },
      { name: 'Burundi', code: 'BI' },
      { name: 'Cambodia', code: 'KH' },
      { name: 'Cameroon', code: 'CM' },
      { name: 'Canada', code: 'CA' },
      { name: 'Cape Verde', code: 'CV' },
      { name: 'Cayman Islands', code: 'KY' },
      { name: 'Central African Republic', code: 'CF' },
      { name: 'Chad', code: 'TD' },
      { name: 'Chile', code: 'CL' },
      { name: 'China', code: 'CN' },
      { name: 'Christmas Island', code: 'CX' },
      { name: 'Cocos (Keeling) Islands', code: 'CC' },
      { name: 'Colombia', code: 'CO' },
      { name: 'Comoros', code: 'KM' },
      { name: 'Congo', code: 'CG' },
      { name: 'Congo, The Democratic Republic of the', code: 'CD' },
      { name: 'Cook Islands', code: 'CK' },
      { name: 'Costa Rica', code: 'CR' },
      { name: "Cote D'Ivoire", code: 'CI' },
      { name: 'Croatia', code: 'HR' },
      { name: 'Cuba', code: 'CU' },
      { name: 'Cyprus', code: 'CY' },
      { name: 'Czech Republic', code: 'CZ' },
      { name: 'Denmark', code: 'DK' },
      { name: 'Djibouti', code: 'DJ' },
      { name: 'Dominica', code: 'DM' },
      { name: 'Dominican Republic', code: 'DO' },
      { name: 'Ecuador', code: 'EC' },
      { name: 'Egypt', code: 'EG' },
      { name: 'El Salvador', code: 'SV' },
      { name: 'Equatorial Guinea', code: 'GQ' },
      { name: 'Eritrea', code: 'ER' },
      { name: 'Estonia', code: 'EE' },
      { name: 'Ethiopia', code: 'ET' },
      { name: 'Falkland Islands (Malvinas)', code: 'FK' },
      { name: 'Faroe Islands', code: 'FO' },
      { name: 'Fiji', code: 'FJ' },
      { name: 'Finland', code: 'FI' },
      { name: 'France', code: 'FR' },
      { name: 'French Guiana', code: 'GF' },
      { name: 'French Polynesia', code: 'PF' },
      { name: 'French Southern Territories', code: 'TF' },
      { name: 'Gabon', code: 'GA' },
      { name: 'Gambia', code: 'GM' },
      { name: 'Georgia', code: 'GE' },
      { name: 'Germany', code: 'DE' },
      { name: 'Ghana', code: 'GH' },
      { name: 'Gibraltar', code: 'GI' },
      { name: 'Greece', code: 'GR' },
      { name: 'Greenland', code: 'GL' },
      { name: 'Grenada', code: 'GD' },
      { name: 'Guadeloupe', code: 'GP' },
      { name: 'Guam', code: 'GU' },
      { name: 'Guatemala', code: 'GT' },
      { name: 'Guernsey', code: 'GG' },
      { name: 'Guinea', code: 'GN' },
      { name: 'Guinea-Bissau', code: 'GW' },
      { name: 'Guyana', code: 'GY' },
      { name: 'Haiti', code: 'HT' },
      { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
      { name: 'Holy See (Vatican City State)', code: 'VA' },
      { name: 'Honduras', code: 'HN' },
      { name: 'Hong Kong', code: 'HK' },
      { name: 'Hungary', code: 'HU' },
      { name: 'Iceland', code: 'IS' },
      { name: 'India', code: 'IN' },
      { name: 'Indonesia', code: 'ID' },
      { name: 'Iran, Islamic Republic Of', code: 'IR' },
      { name: 'Iraq', code: 'IQ' },
      { name: 'Ireland', code: 'IE' },
      { name: 'Isle of Man', code: 'IM' },
      { name: 'Israel', code: 'IL' },
      { name: 'Italy', code: 'IT' },
      { name: 'Jamaica', code: 'JM' },
      { name: 'Japan', code: 'JP' },
      { name: 'Jersey', code: 'JE' },
      { name: 'Jordan', code: 'JO' },
      { name: 'Kazakhstan', code: 'KZ' },
      { name: 'Kenya', code: 'KE' },
      { name: 'Kiribati', code: 'KI' },
      { name: "Korea, Democratic People'S Republic of", code: 'KP' },
      { name: 'Korea, Republic of', code: 'KR' },
      { name: 'Kuwait', code: 'KW' },
      { name: 'Kyrgyzstan', code: 'KG' },
      { name: "Lao People'S Democratic Republic", code: 'LA' },
      { name: 'Latvia', code: 'LV' },
      { name: 'Lebanon', code: 'LB' },
      { name: 'Lesotho', code: 'LS' },
      { name: 'Liberia', code: 'LR' },
      { name: 'Libyan Arab Jamahiriya', code: 'LY' },
      { name: 'Liechtenstein', code: 'LI' },
      { name: 'Lithuania', code: 'LT' },
      { name: 'Luxembourg', code: 'LU' },
      { name: 'Macao', code: 'MO' },
      { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
      { name: 'Madagascar', code: 'MG' },
      { name: 'Malawi', code: 'MW' },
      { name: 'Malaysia', code: 'MY' },
      { name: 'Maldives', code: 'MV' },
      { name: 'Mali', code: 'ML' },
      { name: 'Malta', code: 'MT' },
      { name: 'Marshall Islands', code: 'MH' },
      { name: 'Martinique', code: 'MQ' },
      { name: 'Mauritania', code: 'MR' },
      { name: 'Mauritius', code: 'MU' },
      { name: 'Mayotte', code: 'YT' },
      { name: 'Mexico', code: 'MX' },
      { name: 'Micronesia, Federated States of', code: 'FM' },
      { name: 'Moldova, Republic of', code: 'MD' },
      { name: 'Monaco', code: 'MC' },
      { name: 'Mongolia', code: 'MN' },
      { name: 'Roboto', code: 'MS' },
      { name: 'Morocco', code: 'MA' },
      { name: 'Mozambique', code: 'MZ' },
      { name: 'Myanmar', code: 'MM' },
      { name: 'Namibia', code: 'NA' },
      { name: 'Nauru', code: 'NR' },
      { name: 'Nepal', code: 'NP' },
      { name: 'Netherlands', code: 'NL' },
      { name: 'Netherlands Antilles', code: 'AN' },
      { name: 'New Caledonia', code: 'NC' },
      { name: 'New Zealand', code: 'NZ' },
      { name: 'Nicaragua', code: 'NI' },
      { name: 'Niger', code: 'NE' },
      { name: 'Nigeria', code: 'NG' },
      { name: 'Niue', code: 'NU' },
      { name: 'Norfolk Island', code: 'NF' },
      { name: 'Northern Mariana Islands', code: 'MP' },
      { name: 'Norway', code: 'NO' },
      { name: 'Oman', code: 'OM' },
      { name: 'Pakistan', code: 'PK' },
      { name: 'Palau', code: 'PW' },
      { name: 'Palestinian Territory, Occupied', code: 'PS' },
      { name: 'Panama', code: 'PA' },
      { name: 'Papua New Guinea', code: 'PG' },
      { name: 'Paraguay', code: 'PY' },
      { name: 'Peru', code: 'PE' },
      { name: 'Philippines', code: 'PH' },
      { name: 'Pitcairn', code: 'PN' },
      { name: 'Poland', code: 'PL' },
      { name: 'Portugal', code: 'PT' },
      { name: 'Puerto Rico', code: 'PR' },
      { name: 'Qatar', code: 'QA' },
      { name: 'Reunion', code: 'RE' },
      { name: 'Romania', code: 'RO' },
      { name: 'Russian Federation', code: 'RU' },
      { name: 'RWANDA', code: 'RW' },
      { name: 'Saint Helena', code: 'SH' },
      { name: 'Saint Kitts and Nevis', code: 'KN' },
      { name: 'Saint Lucia', code: 'LC' },
      { name: 'Saint Pierre and Miquelon', code: 'PM' },
      { name: 'Saint Vincent and the Grenadines', code: 'VC' },
      { name: 'Samoa', code: 'WS' },
      { name: 'San Marino', code: 'SM' },
      { name: 'Sao Tome and Principe', code: 'ST' },
      { name: 'Saudi Arabia', code: 'SA' },
      { name: 'Senegal', code: 'SN' },
      { name: 'Serbia and Montenegro', code: 'CS' },
      { name: 'Seychelles', code: 'SC' },
      { name: 'Sierra Leone', code: 'SL' },
      { name: 'Singapore', code: 'SG' },
      { name: 'Slovakia', code: 'SK' },
      { name: 'Slovenia', code: 'SI' },
      { name: 'Solomon Islands', code: 'SB' },
      { name: 'Somalia', code: 'SO' },
      { name: 'South Africa', code: 'ZA' },
      { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
      { name: 'Spain', code: 'ES' },
      { name: 'Sri Lanka', code: 'LK' },
      { name: 'Sudan', code: 'SD' },
      { name: 'Suriname', code: 'SR' },
      { name: 'Svalbard and Jan Mayen', code: 'SJ' },
      { name: 'Swaziland', code: 'SZ' },
      { name: 'Sweden', code: 'SE' },
      { name: 'Switzerland', code: 'CH' },
      { name: 'Syrian Arab Republic', code: 'SY' },
      { name: 'Taiwan, Province of China', code: 'TW' },
      { name: 'Tajikistan', code: 'TJ' },
      { name: 'Tanzania, United Republic of', code: 'TZ' },
      { name: 'Thailand', code: 'TH' },
      { name: 'Timor-Leste', code: 'TL' },
      { name: 'Togo', code: 'TG' },
      { name: 'Tokelau', code: 'TK' },
      { name: 'Tonga', code: 'TO' },
      { name: 'Trinidad and Tobago', code: 'TT' },
      { name: 'Tunisia', code: 'TN' },
      { name: 'Turkey', code: 'TR' },
      { name: 'Turkmenistan', code: 'TM' },
      { name: 'Turks and Caicos Islands', code: 'TC' },
      { name: 'Tuvalu', code: 'TV' },
      { name: 'Uganda', code: 'UG' },
      { name: 'Ukraine', code: 'UA' },
      { name: 'United Arab Emirates', code: 'AE' },
      { name: 'United Kingdom', code: 'GB' },
      { name: 'United States', code: 'US' },
      { name: 'United States Minor Outlying Islands', code: 'UM' },
      { name: 'Uruguay', code: 'UY' },
      { name: 'Uzbekistan', code: 'UZ' },
      { name: 'Vanuatu', code: 'VU' },
      { name: 'Venezuela', code: 'VE' },
      { name: 'Viet Nam', code: 'VN' },
      { name: 'Virgin Islands, British', code: 'VG' },
      { name: 'Virgin Islands, U.S.', code: 'VI' },
      { name: 'Wallis and Futuna', code: 'WF' },
      { name: 'Western Sahara', code: 'EH' },
      { name: 'Yemen', code: 'YE' },
      { name: 'Zambia', code: 'ZM' },
      { name: 'Zimbabwe', code: 'ZW' },
    ];
  }

  public getTestimonials() {
    return [
      {
        text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.',
        author: 'Mr. Adam Sandler',
        position: 'General Director',
        image: 'assets/images/profile/adam.jpg',
      },
      {
        text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.',
        author: 'Ashley Ahlberg',
        position: 'Housewife',
        image: 'assets/images/profile/ashley.jpg',
      },
      {
        text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.',
        author: 'Bruno Vespa',
        position: 'Blogger',
        image: 'assets/images/profile/bruno.jpg',
      },
      {
        text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.',
        author: 'Mrs. Julia Aniston',
        position: 'Marketing Manager',
        image: 'assets/images/profile/julia.jpg',
      },
    ];
  }

  public getChefs() {
    return [
      {
        id: 1,
        fullName: 'Andy Warhol',
        position: 'Head of Chef',
        desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',
        organization: 'Restaurant',
        email: 'andy.w@mox.com',
        phone: '(212) 457-2308',
        social: {
          facebook: 'andy.warhol',
          twitter: 'andy.warhol',
          linkedin: 'andy.warhol',
          instagram: 'andy.warhol',
          website: 'https://andy.warhol.com',
        },
        ratingsCount: 4,
        ratingsValue: 400,
        image: 'assets/images/chefs/1.jpg',
      },
      {
        id: 2,
        fullName: 'Lusia Manuel',
        position: 'Assistant Chef',
        desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',
        organization: 'Restaurant',
        email: 'lusia.m@mox.com',
        phone: '(224) 267-1346',
        social: {
          facebook: 'lusia.manuel',
          twitter: 'lusia.manuel',
          linkedin: 'lusia.manuel',
          instagram: 'lusia.manuel',
          website: 'https://lusia.manuel.com',
        },
        ratingsCount: 6,
        ratingsValue: 480,
        image: 'assets/images/chefs/2.jpg',
      },
      {
        id: 3,
        fullName: 'Michael Blair',
        position: 'Intern Chef',
        desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',
        organization: 'Restaurant',
        email: 'michael.b@mox.com',
        phone: '(267) 388-1637',
        social: {
          facebook: 'michael.blair',
          twitter: 'michael.blair',
          linkedin: 'michael.blair',
          instagram: 'michael.blair',
          website: 'https://michael.blair.com',
        },
        ratingsCount: 4,
        ratingsValue: 400,
        image: 'assets/images/chefs/3.jpg',
      },
      {
        id: 4,
        fullName: 'Tereza Stiles',
        position: 'Assistant Chef',
        desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',
        organization: 'Restaurant',
        email: 'tereza.s@mox.com',
        phone: '(214) 617-2614',
        social: {
          facebook: 'tereza.stiles',
          twitter: 'tereza.stiles',
          linkedin: 'tereza.stiles',
          instagram: 'tereza.stiles',
          website: 'https://tereza.stiles.com',
        },
        ratingsCount: 4,
        ratingsValue: 380,
        image: 'assets/images/chefs/4.jpg',
      },
      {
        id: 5,
        fullName: 'Michelle Ormond',
        position: 'Head of Chef',
        desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',
        organization: 'Restaurant',
        email: 'michelle.o@mox.com',
        phone: '(267) 388-1637',
        social: {
          facebook: 'michelle.ormond',
          twitter: 'michelle.ormond',
          linkedin: 'michelle.ormond',
          instagram: 'michelle.ormond',
          website: 'https://michelle.ormond.com',
        },
        ratingsCount: 6,
        ratingsValue: 480,
        image: 'assets/images/chefs/5.jpg',
      },
    ];
  }

  public getAwards() {
    return [
      { name: 'award-1', image: 'assets/images/awards/1.png' },
      { name: 'award-2', image: 'assets/images/awards/2.png' },
      { name: 'award-3', image: 'assets/images/awards/3.png' },
      { name: 'award-4', image: 'assets/images/awards/4.png' },
      { name: 'award-5', image: 'assets/images/awards/5.png' },
      { name: 'award-6', image: 'assets/images/awards/6.png' },
      { name: 'award-7', image: 'assets/images/awards/7.png' },
    ];
  }

  public getDeliveryMethods() {
    return [
      {
        value: 'free',
        name: 'Free Delivery',
        desc: '$0.00 / Delivery in 7 to 14 business Days',
      },
      {
        value: 'standard',
        name: 'Standard Delivery',
        desc: '$7.99 / Delivery in 5 to 7 business Days',
      },
      {
        value: 'express',
        name: 'Express Delivery',
        desc: '$29.99 / Delivery in 1 business Days',
      },
    ];
  }

  public getMonths() {
    return [
      { value: '01', name: 'January' },
      { value: '02', name: 'February' },
      { value: '03', name: 'March' },
      { value: '04', name: 'April' },
      { value: '05', name: 'May' },
      { value: '06', name: 'June' },
      { value: '07', name: 'July' },
      { value: '08', name: 'August' },
      { value: '09', name: 'September' },
      { value: '10', name: 'October' },
      { value: '11', name: 'November' },
      { value: '12', name: 'December' },
    ];
  }

  public getYears() {
    const startYear = new Date().getFullYear();
    let years = Array();
    for (let i = 0; i <= 10; i++) {
      years.push(startYear + i);
    }
    return years;
  }

  public shuffleArray(array: any) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  public convertImgToBase64(url: any, callback: any) {
    
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  
  }

  async getBase64ImageFromUrl(imageUrl:any) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

   private mailApi = 'https://mailthis.to/codeninja';
  public PostMessage(input: any) {
    return this.http.post(this.mailApi, input, { responseType: 'text' }).pipe(
      map(
        (response: any) => {
          if (response) {
            return response;
          }
        },
        (error: any) => {
          return error;
        }
      )
    );
  }
}
