import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from "../models/user";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
 // private currentUserSubject: BehaviorSubject<User>;
 // public currentUser: Observable<User>;

 public _loginURL = environment.API_URL+"account/connect"

  constructor(private http: HttpClient) {
    // this.currentUserSubject = new BehaviorSubject<User>(
    //   JSON.parse(localStorage.getItem('currentUser')! )
    // );
    // this.currentUser = this.currentUserSubject.asObservable();
  }

  // public get currentUserValue(): User {
  //   return this.currentUserSubject.value;
  // }


  // { headers: { 'Access-Control-Allow-Origin': '*' ,
  //                                                                     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS' ,
  //                                                                     "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With" }, }

  login(username: string, password: string) {
    return this.http
      .post<any>(this._loginURL, { username, password,})
      .pipe(map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if(user.token!==null){
            localStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.setItem('currenttoken', user.token);
           return user;
          }
          else{
            return user;
          }
        })
      );
  }
  

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currenttoken');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('vendorid');
    localStorage.removeItem('vendorname');
    localStorage.removeItem('busname');
    localStorage.removeItem('role');
    localStorage.removeItem('web_token');
    localStorage.removeItem('beepsoundloaded')

    //this.currentUserSubject.next(null);
    return of({ success: false });
  }


  getToken(){
    return localStorage.getItem('currenttoken')
  }

  public createAuthorizationHeader( _HttpHeaders: HttpHeaders) {
    _HttpHeaders.set('Authorization', 'Bearer ' +
      this.getToken); 
  }

  loggedIn() {
    return !!localStorage.getItem('currenttoken')    
  }

 


}
