import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   if (this.authService.currentUserValue.token) {
  //     const userRole = this.authService.currentUserValue.role;
  //     if (route.data.role && route.data.role.indexOf(userRole) === -1) {
  //       this.router.navigate(['/']);
  //       return false;
  //     }
  //     return true;
  //   }

  //   this.router.navigate(['/']);
  //   return false;
  // }

  canActivate(): boolean {
    if (this.authService.loggedIn()) {
      //console.log('true')
      return true
    } else {
      //console.log('false')            
      this.router.navigate(['/'])
      return false
    }
  }

}
