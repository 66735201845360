/// <reference types="web-bluetooth" />

import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { C, COMMA, ENTER, I } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppService } from "src/app/app.service";
import { DatePipe } from "@angular/common";
import { Moment } from "moment";
import * as moment from "moment";
import { environment } from "src/environments/environment";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Order } from "src/app/app.models";
import jsPDF from "jspdf";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { PrintService, UsbDriver } from 'ng-thermal-print';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

//const sb = new StringBuilder();

import html2canvas from "html2canvas";
import { Console } from "console";
import { appendFile } from "fs";

@Component({
  selector: "app-order-invoice-dialog",
  templateUrl: "./order-invoice-dialog.component.html",
  styleUrls: ["./order-invoice-dialog.component.scss"],
})
export class OrderInvoiceDialogComponent implements OnInit {
  displayedColumns: string[] = [
    "menuName",
    "qty",
    "rate",
  ];
  dataSource!: MatTableDataSource<Order>;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild("pdfTable") pdfTable!: ElementRef<any>;

  public status: boolean = false;
  public usbPrintDriver = new UsbDriver();
  public printCharacteristic: any;
  public storage:any;
  constructor(
    public dialogRef: MatDialogRef<OrderInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: UntypedFormBuilder,
    public appService: AppService,
    public datepipe: DatePipe,
    public snackBar: MatSnackBar,
    private printService: PrintService
  ) {
    this.printService.isConnected.subscribe((result: boolean) => {
      this.status = result;
      // window.alert(JSON.stringify(this.usbPrintDriver))
      if (result) {
          // window.alert('Connected to printer!!!');
      } else {
      // window.alert('Not connected to printer.');
      }
  });
  }

  ngOnInit(): void {
   console.log(this.data)
    // this.printThermalPrinter();
    this.initDataSource(this.data.det.orderItem)
  }

  public initDataSource(data: any) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  requestUsb() {
    console.log(localStorage.getItem('printer-device'))
    if(this.status && (localStorage.getItem('printer-device') !== null || localStorage.getItem('printer-device') !== undefined || localStorage.getItem('printer-device') !== '')){
        this.storage = localStorage.getItem('printer-device');
        const device = JSON.parse(this.storage);
        this.usbPrintDriver = new UsbDriver(device.vendorId, device.productId);
        let language = (device.vendorId === 1305)?'StarPRNT': 'ESC/POS';
        this.printService.setDriver(this.usbPrintDriver, language);
        // console.log(this.usbPrintDriver)
    // this.printThermalPrinter();
}
    else {
    this.printThermalPrinter();

    this.usbPrintDriver.requestUsb().subscribe((result: any) => {
        let language = (result.vendorId === 1305)?'StarPRNT': 'ESC/POS';
        console.log(result.vendorId, result.productId,language)
        localStorage.setItem('printer-device', JSON.stringify({ vendorId: result.vendorId, productId: result.productId }));
    this.printService.setDriver(new UsbDriver(result.vendorId,result.productId), 'ESC/POS');
    });
}

}
async printThermalPrinter() {
    try {
  const device = await navigator.bluetooth.requestDevice({
    acceptAllDevices: true,
    optionalServices: ['device_information']
  }
).then(async device => {
        let server = await device.gatt?.connect();
       })
} catch (error) {
  console.error(error);
}
}

// printThermalPrinter () {
//   // const self = device
//   // device.addEventListener('gattserverdisconnected', this.onDisconnected)
  
//  const  device =  
//  navigator.bluetooth.requestDevice({
//     //     acceptAllDevices: true,
//     // optionalServices: ['device_information'],
    
//       filters: [
//         {
//           name: 'MPT-II',
//           services: [0xFEE7, 0x18F0,'e7810a71-73ae-499d-8c15-faa9aef0c3f2']  
//           // services: [0x1234, 0x12345678, '99999999-0000-1000-8000-00805f9b34fb']

//         }
//       ],
//       optionalServices: ['4953535343-fe7d-4ae5-8fa9-9fafd205e455']
//     }
//     // {
//     // }
    
//     ).then((device:any) => {
//   return device.gatt
//     .connect()
//     })
//     .then((server:any) =>{
//       console.log(server);
//        server.getPrimaryService('e7810a71-73ae-499d-8c15-faa9aef0c3f2')
//       console.log(server);
//       }
//      )
//      .then((service:any) =>{
//        service.getCharacteristic('e7810a71-73ae-499d-8c15-faa9aef0c3f2')
//       console.log(service);

//      }
//      )
//      .then((characteristic:any) => {
//        console.log('characteristic', characteristic)
//        this.printCharacteristic = characteristic
//        this.sendTextData(device)
//      })
//      .catch((error:any) => {
//        // this.handleError(error, device)
//      })
// }
getBytes (text:any) {
  console.log('text', text)
  let br = '\u000A\u000D'
  text = text === undefined ? br : text
  // let replaced = this.$languages.replace(text)
  let replaced = ''
  console.log('replaced', replaced)
  let bytes = new TextEncoder().encode(replaced + br)
  console.log('bytes', bytes)
  return bytes
}
addText (arrayText:any) {
  let text = 'hello'
  arrayText.push(text)
  // if (this.isMobile) {
    while (text.length >= 20) {
      let text2 = text.substring(20)
      arrayText.push(text2)
      text = text2
    }
  // }
}
sendTextData(device:any) {
  let arrayText: never[] = [];
  this.addText(arrayText)
  console.log('sendTextData => arrayText', arrayText)
  this.loop(0, arrayText, device)
}
loop(i:any, arrayText:any, device:any) {
  let arrayBytes = this.getBytes(arrayText[i])
  this.write(device, arrayBytes, () => {
    i++
    if (i < arrayText.length) {
      this.loop(i, arrayText, device)
    } else {
      // let arrayBytes = this.getBytes()
      this.write(device, arrayBytes, () => {
        device.gatt.disconnect()
      })
    }
  })
}
write(device:any, array:any, callback:any) {
  this.printCharacteristic
    .writeValue(array)
    .then(() => {
      console.log('Printed Array: ' + array.length)
      setTimeout(() => {
        if (callback) {
          callback()
        }
      }, 250)
    })
    .catch((error:any) => {
      // this.handleError(error, device)
    })
}
  GenerateReport() {
  }
  

  print() {
   
    var quantity = 0;
    var sno = 0;
    this.printService.init()
    .setSize('large')
    .setBold(true)
    .setJustification('center')
    .writeLine(this.data.det.trnsOrder.deliveryType === 'Home Delivery' ? 'HD\n' : this.data.det.trnsOrder.deliveryType === 'Curb Side Pickup' ? 'CSD\n': this.data.det.trnsOrder.deliveryType === 'Self-Pick Up' ? 'SPU\n':'HD\n' )
    
    .setSize('normal')
    .setBold(true)
    .setJustification('center')
    .writeLine(this.data.det.vendorDetails.businessName)
    .setSize('normal')
    .setJustification('center')
    .writeLine('0'+this.data.det.vendorDetails.contactNo+'\n')
    .setJustification('left')
    .writeLine('Order No: '+this.data.res.orderNo)
    .writeLine('Customer Name: '+this.data.res.customerName)
    .writeLine('Phone No: '+this.data.det.orderAddress[0].contactNo)
    .writeLine('Order Date: '+ moment(this.data.det.trnsOrder.orderDate).format("DD/MM/YYYY"))

    .feed(1)
    .cut('full')
    .flush();
  //   if(this.data.det.trnsOrder.deliveryType === 'Self-Pick Up'){
  //     this.printService.init()
  // .setBold(false)
  // .setJustification('left')
  // .setSize('normal')
  //   .writeLine('')
  //   .feed(1)
  //   .cut('full')
  // .flush();
  //   }
    this.printService.init()
  .setBold(false)
  .setJustification('left')
  .setSize('normal')
    .writeLine("#" + " Item" + "\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t      Qty " + " Price")
    .feed(1)
    .cut('full')
  .flush();

for (let data of this.data.det.orderItem)
{ 
  var menuName = data.menuName,spaceLength=0,space=' ',menuLength=0;
  console.log(data,data.menuName)
  if(menuName.length > 30){
    menuName = menuName.substring(0,15) + "\r"+"   "+menuName.substring(16,30)+"   "+menuName.substring(31,menuName.length) ;
    menuLength=menuName.substring(16,30).length
  }
  else if(menuName.length > 15){
    // if(menuName.substring(12,menuName.length).indexOf('') >= 0){
     
    //   menuName =  menuName.substring(16,menuName.length).indexOf('')
    //   console.log(menuName)
    // }
    menuName = menuName.substring(0,15) + "\r"+"   "+menuName.substring(15,menuName.length);
    menuLength=menuName.substring(16,menuName.length).length-3
    console.log(menuLength)
  }
  else{
    menuLength=menuName.length
  }   
  quantity = quantity + data.qty;
  sno = sno + 1;
  console.log(quantity,sno)
  for(let i=spaceLength;i<18-menuLength;i++){
    space = space+' ';
  }
  this.printService.init()
    .setBold(false)
    .setJustification('left')
    .setSize('normal')
    .setUnderline(false)
    .writeLine(sno+". " + menuName +space+data.qty + "    "+data.itemValue.toFixed(2))
  .feed(1)
  .cut('full')
    .flush();
  }
    
  this.printService.init()
  .writeLine(" _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ ")
    .flush();
    this.printService.init()
    .setUnderline(false)
    .writeLine("Order Value" +  "           "+quantity + "    "+this.data.det.trnsOrder.grossValue.toFixed(2))
      // .feed(1)
      .cut('full')
      .flush();
    if(this.data.det.trnsOrder.taxAmount > 0){
      this.printService.init()
      .setUnderline(false)
        .writeLine("VAT (5%)   " +  "               " + " 0"+ this.data.det.trnsOrder.taxAmount.toFixed(2))
        // .feed(1)
        .cut('full')
        .flush();
      }
  if(this.data.det.trnsOrder.promoAmt > 0){
    this.printService.init()
      .writeLine("Promotional Discount" +  "      -0" +  this.data.det.trnsOrder.promoAmt.toFixed(2))
      // .feed(1)
      .cut('full')
      .flush();
    }
  if(this.data.det.trnsOrder.tpointUsed > 0){
  this.printService.init()
    .writeLine("Tpoints Used" +  "              -0" + this.data.det.trnsOrder.tpointUsed.toFixed(2))
    .cut('full')
    .flush();
  }
  
  this.printService.init()
  .writeLine(" _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ ")
  .flush();
    this.printService.init()
      .setBold(true)
    .writeLine("Total Bill Value" +  "           "+  this.data.det.trnsOrder.netAmount.toFixed(2))
  .writeLine(" _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ ")
  .setUnderline(false)
    .setBold(false)
      .writeLine("Address: " +this.data.det.orderAddress[0].buildingName +", "+ this.data.det.orderAddress[0].streetName+", "+this.data.det.orderAddress[0].geoLocation)
      .feed(2)
      .cut('full')
      .flush();
}



  // GenerateReport() {
  //   // let DATA: any = document.getElementById('pdfTable');
  //   // html2canvas(DATA).then((canvas) => {
  //   //   let fileWidth = 208;
  //   //   let fileHeight = (canvas.height * fileWidth) / canvas.width;
  //   //   const FILEURI = canvas.toDataURL('image/png');
  //   //   let PDF = new jsPDF('p', 'mm', 'a4');
  //   //   let position = 0;
  //   //   PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);

  //   //   PDF.save('angular-demo.pdf');
  //   // });

  //   //  const element: any = document.getElementById('pdfTable');
  //   //  let myWindows :any = window.open('', 'PRINT', 'height=400,with=600');
  //   //  myWindows.document.writeLine("<!DOCTYPE html><html><head><meta charset='utf-8'><meta http-equiv='X-UA-Compatible' content='IE=edge'>"+
  //   //  + "<meta name='viewport' content='width=device-width, initial-scale=1'>");
  //   //  myWindows.document.writeLine('</head><body>');
  //   //  myWindows.document.writeLine(element.innerHTML);
  //   //  myWindows.document.writeLine('</body></html>');
  //   // sb.Append("\n\n------------------------------------");
            
  //   //            sb.Append("\nKotNo        : 244554" );
  //   //            sb.Append("\nMember Code  : 552" );
  //   //            sb.Append("\nBill Date    : 54554" );
  //   //            sb.Append("\nMember Name  : 5254253zzx" );
  //   //            sb.Append("\n-----------------------------------");
  //   //            sb.Append("\nItem             Qty.    Rate");//UOM
  //   //            sb.Append("\n-----------------------------------");
  //   //            console.log(sb.toString())
  //   // myWindows.document.writeLine(sb.toString())

  //    //myWindows.document.close();
  //    //myWindows.focus();
  //    //myWindows.print();

  //   let DATA: any = document.getElementById("pdfTable");
  //   console.log(DATA)
  //   html2canvas(DATA,{scale:1}).then((canvas) => {
  //       // let fileWidth = 55;
  //       let fileWidth = 200;
  //       let fileHeight = (canvas.height * fileWidth) / canvas.width;
  //       const FILEURI = canvas.toDataURL('image/png');
  //       // let PDF = new jsPDF('p', 'mm', [297,155]);



        
  //       let PDF = new jsPDF('p', 'mm', 'a4');
  //       // PDF.setFillColor('245');
  //       // PDF.rect(0, 0, 10, 70, "F");

  //       let position = 0;
  //       PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
  
  //       // PDF.save(this.data.vend.vendor.vendorName+'_Order_'+this.data.res.orderNo+'.pdf');
  //       PDF.autoPrint();
  //       PDF.output('dataurlnewwindow');
  //     });

    

  //   // html2canvas(DATA, {}).then((canvas) => {
  //   //   let imgWidth = 208;
  //   //   let pageHeight = 480;
  //   //   let imgHeight = (canvas.height * imgWidth) / canvas.width;
  //   //   var heightLeft = imgHeight;

  //   //   const imgData = canvas.toDataURL("image/png");

  //   //   const pdf = new jsPDF({
  //   //     orientation: "p",
  //   //     unit: "mm",
  //   //     format: "a4",
  //   //   });

  //   //   let position = 0;

  //   //   heightLeft -= pageHeight;

  //   //   while (heightLeft >= 0) {
  //   //     position = heightLeft - imgHeight;
  //   //     pdf.addPage();
  //   //     pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //   //     heightLeft -= pageHeight;
  //   //   }
  //   //   pdf.save("Result.pdf");
  //   // });
  // }
//   print () {
//     navigator.bluetooth
//       .requestDevice(
//         {
//           filters: [
//             {
//               name: 'BlueTooth Printer',
//               services: ['000018f0-0000-1000-8000-00805f9b34fb']
//             }
//           ]
//         },
//         {
//           optionalServices: ['00002af1-0000-1000-8000-00805f9b34fb']
//         }
//       )
//       .then(device => {
//         console.log('device', device)
//         if (device.gatt.connected) {
//           device.gatt.disconnect()
//         }
//         console.log('connect')
//         return this.connect(device)
//       })
//       .catch(this.handleError)
//   },
//   connect (device) {
//     const self = this
//     device.addEventListener('gattserverdisconnected', this.onDisconnected)
//     return device.gatt
//       .connect()
//       .then(server =>
//         server.getPrimaryService('000018f0-0000-1000-8000-00805f9b34fb')
//       )
//       .then(service =>
//         service.getCharacteristic('00002af1-0000-1000-8000-00805f9b34fb')
//       )
//       .then(characteristic => {
//         console.log('characteristic', characteristic)
//         self.printCharacteristic = characteristic
//         self.sendTextData(device)
//       })
//       .catch(error => {
//         this.handleError(error, device)
//       })
//   },
//   handleError (error, device) {
//     console.error('handleError => error', error)
//     if (device != null) {
//       device.gatt.disconnect()
//     }
//     let erro = JSON.stringify({
//       code: error.code,
//       message: error.message,
//       name: error.name
//     })
//     console.log('handleError => erro', erro)
//     if (error.code !== 8) {
//       this.$q.notify('Could not connect with the printer. Try it again')
//     }
//   },
//   getBytes (text) {
//     console.log('text', text)
//     let br = '\u000A\u000D'
//     text = text === undefined ? br : text
//     let replaced = this.$languages.replace(text)
//     console.log('replaced', replaced)
//     let bytes = new TextEncoder('utf-8').encode(replaced + br)
//     console.log('bytes', bytes)
//     return bytes
//   },
//   addText (arrayText) {
//     let text = this.msg
//     arrayText.push(text)
//     if (this.isMobile) {
//       while (text.length >= 20) {
//         let text2 = text.substring(20)
//         arrayText.push(text2)
//         text = text2
//       }
//     }
//   },
//   sendTextData (device) {
//     let arrayText = []
//     this.addText(arrayText)
//     console.log('sendTextData => arrayText', arrayText)
//     this.loop(0, arrayText, device)
//   },
//   loop (i, arrayText, device) {
//     let arrayBytes = this.getBytes(arrayText[i])
//     this.write(device, arrayBytes, () => {
//       i++
//       if (i < arrayText.length) {
//         this.loop(i, arrayText, device)
//       } else {
//         let arrayBytes = this.getBytes()
//         this.write(device, arrayBytes, () => {
//           device.gatt.disconnect()
//         })
//       }
//     })
//   },
//   write (device, array, callback) {
//     this.printCharacteristic
//       .writeValue(array)
//       .then(() => {
//         console.log('Printed Array: ' + array.length)
//         setTimeout(() => {
//           if (callback) {
//             callback()
//           }
//         }, 250)
//       })
//       .catch(error => {
//         this.handleError(error, device)
//       })
//   }
// }
// }
}
