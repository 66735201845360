import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient,HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { vendor, VendorAddress,vendorFeedback } from "../models/register";
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {

    private _vendor_register =environment.API_URL+"account/register-vendor";
    private _vendor_profile =environment.API_URL+"master/vendor/get-profile";
    private _vendor_Address =environment.API_URL+"master/vendor/save-vendoraddress";
    private _vendor_Feedback =environment.API_URL+"master/customer/save-vendorfeedback";
    private _vendor_Update =environment.API_URL+"master/vendor/update-vendor";
    private _vendor_Upload =environment.API_URL+"master/vendor/upload-file";

    constructor(private httpClient: HttpClient,private authenticationService: AuthService ) {}



  //add vendor feedback
  OnFeedback(v:vendorFeedback){

    return this.httpClient.post(this._vendor_Feedback,v);
}
    // vendor register
    Onvendor_register(v:any ): any {
     
         return this.httpClient.post(this._vendor_register,v)
      }

  //Get Vendor Profile
    OnGetVendorProfile(){
      // let headers =   new HttpHeaders();
      // this.authenticationService.createAuthorizationHeader(headers);
      // let options = { headers: {Authorization:'Bearer '+this.authenticationService.getToken()} };
      return this.httpClient.get(this._vendor_profile,{headers: {Authorization:'Bearer '+this.authenticationService.getToken()}});

    }

  //add vendor address
  OnVendorAddress(v:VendorAddress){

      return this.httpClient.post(this._vendor_Address,v,{headers: {Authorization:'Bearer '+this.authenticationService.getToken()}});
  }

  //update vendor
  OnVendorUpdate(ven:vendor){
    return this.httpClient.post(this._vendor_Update,ven,{headers: {Authorization:'Bearer '+this.authenticationService.getToken()}});

  }
  //vendor upload
  OnVendorUpload(formdata:any ){
    // console.log(formdata.getAll('File'))
    // console.log(formdata.getAll('Id'))
    // console.log(formdata.getAll('FileType'))
    return this.httpClient.post(this._vendor_Upload,formdata,{headers: {Authorization:'Bearer '+this.authenticationService.getToken()}});
  } 

    
}