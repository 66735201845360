import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { orders } from 'src/app/admin/dashboard/dashboard.data';
import { Order } from 'src/app/app.models';
import { AppService, Data } from 'src/app/app.service';
import { RegisterService } from '../../core/service/register.service';

@Component({
  selector: 'app-schedule-order-details-dialog',
  templateUrl: './schedule-order-details-dialog.component.html',
  styleUrls: ['./schedule-order-details-dialog.component.scss']
})
export class ScheduleOrderDetailsDialogComponent implements OnInit {
  public deliveryAddress = {
    'firstName': 'Emilio',
    'lastName': 'Verdines',
    'middleName': '',
    'company': '',
    'email': 'emilio.verdines@gmail.com',
    'phone': '+971 123 456 7890',
    'country': 'UAE',
    'city': 'Dubai',
    'place': 'Street 4',
    'postalCode': '11213',
    'address': '1568 Atlantic Ave',
    'id': 1
  }

  public OrderDetails: any;
  public Userlist: any;
  public Select_Userlist: any;
  public Delivery_details: any;

  public VendorDetails: any;
  public VendorAddress: any;
  public role: any;
  constructor(public dialogRef: MatDialogRef<ScheduleOrderDetailsDialogComponent>, public appService: AppService,
    public RegisterService: RegisterService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public order: any) { }


  ngOnInit(): void {
    console.log(this.order)
    this.role = localStorage.getItem("userrole");
    this.OrderDetails = "";
    this.appService.getScheduleOrderInfo(this.order.id).subscribe((resinfo) => {
      console.log(resinfo)
      this.OrderDetails = resinfo;
      this.getOrderDelivery(this.order.id)
    })
    this.getVendorUser();
    this.getVendor();
  }

  public getVendor(){
    this.RegisterService.OnGetVendorProfile().subscribe((dataprofile: any) => {
      console.log(dataprofile)
      this.VendorDetails = dataprofile.vendor;
      this.VendorAddress = dataprofile.address[0];
    })
  }
  public getVendorUser() {
    this.appService.getUsers().subscribe((uslist: any) => {
      // console.log(uslist);
      this.Userlist = uslist.filter((item: any) => item.roleId == 7)
      //console.log(this.Userlist)
    })
  }

  public getOrderDelivery(id: any) {
    this.appService.getDeliveryDetailsbyOrderID(id).subscribe((delres: any) => {
       console.log(delres)
      if (delres != null) {
        this.Delivery_details = delres;
        this.Select_Userlist = this.Userlist.filter((item: any) => item.id == delres.deliveryBoyId)
        // console.log(this.Select_Userlist)
      }
    })
  }



  public remove(ord: any, orderdt: any, removeid: number) {

    let subtotal = 0;
    let total = 0;
    let delfee = 0;
    let tax = 0;
    let taxp = 0;
    console.log(ord)
    console.log(orderdt)
    console.log(removeid)

    var oldorderId = orderdt.orderAddress[0].orderId;
    let oldorder = ord;
    let oldorderdet = orderdt;

    console.log(oldorder)
    console.log(oldorderdet)

    console.log(oldorderdet)

    const message = "Are you want Cancel Item?";
    let dialogRef = this.appService.openConfirmDialog('', message!);
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        console.log(dialogResult)
        this.appService.CancelOrder(oldorderId).subscribe((calres: any) => {
          console.log(calres);
          if (calres.result === "Successfully Cancelled") {
            oldorderdet.orderItem.splice(removeid, 1)
            console.log(oldorderdet.orderItem)
            if (oldorderdet.orderItem.length > 0) {


              for (let i = 0; i < oldorderdet.orderItem.length; i++) {
                subtotal += Number(oldorderdet.orderItem[i].rate)
                taxp = oldorderdet.orderItem[i].tax
              }
              tax = (Number(taxp) * subtotal) / 100;
              delfee = oldorder.deliverycharges
              total = subtotal + tax + delfee;


              let re_order = {
                VendorId: oldorder.vendorId,
                VBId: 0,
                refOrderId: oldorder.id,
                CustomerId: oldorderdet.trnsOrder.customerId,
                GrossValue: subtotal.toFixed(2),
                Discount: 0,
                Tax: tax.toFixed(2),
                Deliverycharges: delfee,
                NetAmount: Number(total).toFixed(2),
                PaymentType: oldorder.paymentType,
                DeliveryType: oldorder.deliveryType,
                VechicleNo: oldorder.vechicleNo,
                DriverName: oldorder.driverName,
                DriverPhone: oldorder.driverPhone,
                ActiveStatus: "New"
              }
              console.log(re_order)
              this.appService.EditerOrder(re_order).subscribe((edi_res: any) => {
                console.log(edi_res)

                for (let i = 0; i < oldorderdet.orderItem.length; i++) {

                  let itemtax = (taxp)
                  let itemtaxvalue = (taxp * oldorderdet.orderItem[i].rate)

                  let Items = {
                    OrderId: edi_res.result.id,
                    MenuId: oldorderdet.orderItem[i].menuId,
                    SubMenuId: oldorderdet.orderItem[i].subItemId,
                    MenuName: oldorderdet.orderItem[i].menuName,
                    Qty: 1.0,
                    Rate: oldorderdet.orderItem[i].rate,
                    ItemValue: oldorderdet.orderItem[i].rate,
                    ExtraAdded: "",
                    ExtraAddValue: 0,
                    IsGiftPack: 0,
                    GiftPackCharge: 0,
                    Discount: 0.0,
                    Tax: itemtax,
                    TaxValue: itemtaxvalue,
                    itemStatus: ""
                  }
                  console.log(Items)

                  this.appService.saveOrderDetails(Items).subscribe((resorderdetails: any) => {
                    console.log(resorderdetails)
                    if (resorderdetails.result == "Sucess") {

                      if (oldorderdet.orderItem.length - 1 == i) {

                        if (oldorder.deliveryType === "Door Delivery") {

                          let orderadd = {
                            CustomerName: oldorder.customerName,
                            CompanyName: oldorderdet.orderAddress[0].companyName,
                            ContactNo: oldorderdet.orderAddress[0].contactNumber,
                            StreetName: oldorderdet.orderAddress[0].streetName,
                            POBoxNo: oldorderdet.orderAddress[0].poBoxNumber,
                            GeoLocation: oldorderdet.orderAddress[0].location,
                            Country: oldorderdet.orderAddress[0].country,
                            OrderId: edi_res.result.id,
                            AddressType: oldorderdet.orderAddress[0].addType
                          }
                          console.log("---")
                          console.log(orderadd)
                          this.appService.saveOrderAddress(orderadd).subscribe((resadd1: any) => {
                            console.log(resadd1)
                            if (resadd1.result === "Sucess") {

                              let paydet = {
                                Id: oldorderdet.orderPayment.id,
                                OrderId: oldorder.id,
                                NewOrderId: edi_res.result.id,
                                NewOrderAmt: total
                              }
                              console.log(paydet)
                              this.appService.edit_order_payment(paydet).subscribe((py_res: any) => {
                                console.log(py_res)
                                if (py_res.result === "Sucess") {
                                  this.dialogRef.close(true);
                                }
                              });
                            }
                          });

                        }
                        else {
                          let paydet = {
                            Id: oldorderdet.orderPayment.id,
                            OrderId: oldorder.id,
                            NewOrderId: edi_res.result.id,
                            NewOrderAmt: total
                          }
                          console.log(paydet)
                          this.appService.edit_order_payment(paydet).subscribe((py_res: any) => {
                            console.log(py_res)
                            if (py_res.result === "Sucess") {
                              this.dialogRef.close(true);
                            }
                          });
                        }

                      }


                    }
                  });
                }

              })


            }
            else {
              this.dialogRef.close(true);
            }




          }
        })
      }
    })
  }


  onStatusSelectionChange(Orderdet: any, Ord: any,status:any) {
    console.log(Orderdet, Ord)
    if (Ord.activeStatus === 'Awaiting Acceptance') {
      const message = "Are want to Process Order?";
      let dialogRef = this.appService.openConfirmDialog('', message!);
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.appService.OrderStatus(Ord.id, status).subscribe((res: any) => {
            console.log(res)
            this.snackBar.open('Order status updated successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
            this.dialogRef.close(true);
          });
        }
      })
    } else if (Ord.activeStatus === 'Processing') {

            const message = "Are Change to Delivery Process Order?";
            let dialogRef = this.appService.openConfirmDialog('', message!);
            dialogRef.afterClosed().subscribe(dialogResult => {
              if (dialogResult) {
                if(Ord.deliveryType!=='Cafe Side Pickup'){
                  this.appService.OrderStatus(Ord.id, status).subscribe((res: any) => {
                    console.log(res)
                    this.snackBar.open('Order status updated successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
                    this.dialogRef.close(true);
                  });
                }
                else if(Ord.deliveryType==='Cafe Side Pickup'){
                  this.appService.OrderStatus(Ord.id, status).subscribe((res: any) => {
                    console.log(res)

                     let del = {
                            "VendorId": Ord.vendorId,
                            "OrderId": Ord.id,
                            "DeliveryType": Ord.deliveryType,
                            "VechicleNo":Ord.vechicleNo,
                            "DriverName" : Ord.driverName,
                            "DriverPhoneNo" : Ord.driverPhone,
                          }

                          this.appService.OrderStatusStartDelivery(del).subscribe((st_res: any) => {
                            console.log(st_res)
                            if (st_res.msgs[0] === "Successfully Inserted") {
                              this.snackBar.open('Delivery process Started:)', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
                              this.dialogRef.close(true);
                            }
                          })
                  });
                }
              }
            })
    }
    else if(status==='Delivered'){
      const message = "Are Change Order to Completed?";
      let dialogRef = this.appService.openConfirmDialog('', message!);
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
        
          this.appService.OrderStatus(Ord.id, status).subscribe((res: any) => {
            console.log(res)
            this.snackBar.open('Order status updated successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
            this.dialogRef.close(true);
          });
        }
      })
    }
    else if(status==='Cancel'){
      const message = "Are want Cancel Order?";
      let dialogRef = this.appService.openConfirmDialog('', message!);
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
        
          this.appService.OrderStatus(Ord.id, status).subscribe((res: any) => {
            console.log(res)
            if(Ord.paymentType==='eWallet'){
              let wal={
                "CustomerId" :Ord.customerId ,
                "VouType" :"Refund",
                "VouSNo" :1,
                "Ledger1" :Ord.customerName,
                "Ledger2" :"eWallet",
                "Credit" :Ord.netAmount,
                "Debit" :0,
                "Notes" : "as per cancel invoice :"+Ord.orderNo,
                "RefNo" : "",
                "PayType" : "Order Cancel",
                "PayRef" : ""
              }
              this.appService.RefundEwallet(wal).subscribe((res:any)=>{
                this.snackBar.open('Order Cancelled!!!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
                this.dialogRef.close(true);
              })
            }else {
              this.snackBar.open('Order Cancelled!!!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
              this.dialogRef.close(true);
            }
            
          });
        }
      })
    }


  }

  onSelectionChange(ev: any, rt: any) {
    console.log(ev.value, rt)
    let username = this.Userlist.filter((item: any) => item.id == ev.value)
    console.log(username)
    if (ev.value != 0) {
      const message = "Did you want " + username[0].userName + " to Start Delivery ?";
      let dialogRef = this.appService.openConfirmDialog('', message!);
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {

          let del = {
            "VendorId": rt.vendorId,
            "OrderId": rt.id,
            "DeliveryType": rt.deliveryType,
            "DeliveryBoyId": ev.value
          }

          this.appService.OrderStatusStartDelivery(del).subscribe((st_res: any) => {
            console.log(st_res)
            if (st_res.msgs[0] === "Successfully Inserted") {
              this.snackBar.open('Delivery process Started:)', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
              this.dialogRef.close(true);
            }
          })


        }
      })
    }
    else {
      this.snackBar.open('Please Select Delivery Boy !!!!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }

  }

}
